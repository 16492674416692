import { createSlice } from "@reduxjs/toolkit";
import { linkDataType, linkStateType } from "src/@types/models/link";
import { createIdList } from "src/utils/dataUtils";
import { dispatch } from "../store";

const initialState: linkStateType = {
    loaded: false,
    idList: [],
    links: {},
    order: [],
};


const slice = createSlice({
    name: 'link',
    initialState,
    reducers: {
        selectLink(state: linkStateType, action: any) {
            const linkList = action.payload;
            const newDatas: any = {};

            for (const link of linkList) {
                newDatas[link.id] = link;
            }

            const order = state.order;
            const idList = createIdList(newDatas, order);

            state.loaded = true;
            state.links = newDatas
            state.idList = idList
        },

        updateLink(state: linkStateType, action: any) {
            const link = action.payload;
            const newDatas = { ...state.links };
            newDatas[link.id] = link;
            state.links = newDatas;

            const idList = [...state.idList];
            if (!idList.includes(link.id)) {
                idList.push(link.id);
            }
            state.idList = idList;
        },

        updateLinkOrder(state: linkStateType, action: any) {
            const order = action.payload;
            const idList = createIdList(state.links, order);

            state.order = order
            state.idList = idList
        },

        deleteLink(state: linkStateType, action: any) {
            const id = action.payload;
            const newDatas = { ...state.links };
            delete newDatas[id];
            state.links = newDatas

            const idList = [...state.idList];
            const newIdList = idList.filter((e) => {
                if (e === id) {
                    return false;
                } else {
                    return true;
                }
            });
            state.idList = newIdList;
        },

        clearLink(state: linkStateType) {
            state = {
                loaded: false,
                links: {},
                idList: [],
                order: [],
            }
        },
    },
});

// Reducer
export default slice;

