import queryString from "query-string";
import useResponsive from './useResponsive';

// ----------------------------------------------------------------------
export type SourceType = 'app' | 'app-v2' | 'browser' | 'app_legacy';
export type HardwareType = 'mobile' | 'tablet' | 'desktop';
export type RootType = 'content' | 'dashboard' | 'other';

export default function useEnvironment() {
    const { pathname } = window.location;
    const isDesktop = useResponsive('up', 'sm');

    var source: SourceType = 'browser';
    var hardware: HardwareType = 'mobile';

    const query = queryString.parse(window.location.search);
    if (query.onApp === "Y") {
        source = 'app_legacy';
    }
    else if (pathname.includes('/native-v2')) {
        source = 'app-v2';
    }
    else if (pathname.includes('/native')) {
        source = 'app';
    }

    if (isDesktop) {
        hardware = 'desktop';
    }

    return { source, hardware }
}
