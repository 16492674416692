// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_CONTENT = '/content';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_STORE = {
  root: 'https://www.minibig.kr?cate_no=26&utm_source=in_web&utm_medium=from_web&utm_id=in_web#enp_mbris',
};

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  exit: path(ROOTS_AUTH, '/exit'),
  // login: path(ROOTS_AUTH, '/login'),
  // register: path(ROOTS_AUTH, '/register'),
  // loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  // registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  // verify: path(ROOTS_AUTH, '/verify'),
  // resetPassword: path(ROOTS_AUTH, '/reset-password'),
};

export const PATH_CONTENT = {
  root: ROOTS_CONTENT,
  new: path(ROOTS_CONTENT, '/new'),
  edit: (id: number | string) => `/content/edit/${id}`,
  detail: (id: number | string) => `/content/detail/${id}`,
  searchResult: (value: string, isTag: boolean) => `/contents/searchResult?value=${value}&isTag=${isTag ? 'Y' : 'N'}`,
}

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
    place: path(ROOTS_DASHBOARD, '/place'),
    life: path(ROOTS_DASHBOARD, '/life'),
    link: path(ROOTS_DASHBOARD, '/link'),
    scenario: path(ROOTS_DASHBOARD, '/scenario'),
    log: path(ROOTS_DASHBOARD, '/log'),
    room: path(ROOTS_DASHBOARD, '/room'),
  },
};

export const PATH_SINGLE = {
  root: (screen: string, params?: any) => {
    var query = '';
    if (params) {
      query = `?params=${JSON.stringify(params)}`
    }
    return `/single/${screen}${query}`;
  },
};

export const PATH_NATIVE = {
  root: (screen: string, params?: any) => {
    var query = '';
    if (params) {
      query = `?params=${JSON.stringify(params)}`
    }
    return `/native/${screen}${query}`;
  },
};

export const PATH_NATIVE_V2 = {
  root: (screen: string, params?: any) => {
    var query = '';
    if (params) {
      query = `?params=${JSON.stringify(params)}`
    }
    return `/native-v2/${screen}${query}`;
  },
};

export const PATH_PAGE = {
  home: '/',
  notAuth: '/not-auth',
  page404: '/404',
  page500: '/500',
};
