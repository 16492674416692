//? ==== components ====
import { SxProps, styled } from '@mui/material';
import Lottie from 'react-lottie-player';
import ProgressBar from '../../providers/ProgressBar';
import * as animationData from '../../assets/anim/loading_plait.json'

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  right: 0,
  bottom: 0,
  zIndex: 99999,
  width: '100%',
  height: '100%',
  position: 'fixed',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.default,
}));

// ----------------------------------------------------------------------

type Props = {
  onlyProgress?: boolean;
  sx?: SxProps;
};

export default function LoadingScreen({ onlyProgress, ...other }: Props) {
  return (
    <>
      <ProgressBar />

      {!onlyProgress && (
        <RootStyle {...other}>
          <Lottie
            style={{ width: 400, height: 400 }}
            animationData={animationData}
            loop
            play
            rendererSettings={{
              preserveAspectRatio: "xMidYMid slice"
            }}
          />
        </RootStyle>
      )}
    </>
  );
}
