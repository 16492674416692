import { createSlice } from "@reduxjs/toolkit";
import { scenarioDataType, scenarioStateType } from "src/@types/models/scenario";
import { createIdList } from "src/utils/dataUtils";
import { dispatch } from "../store";

const initialState: scenarioStateType = {
    loaded: false,
    idList: [],
    scenarios: {},
    order: [],
};

const slice = createSlice({
    name: 'scenario',
    initialState,
    reducers: {
        selectScenario(state: scenarioStateType, action: any) {
            const scenarioList = action.payload;
            const newDatas: any = {};

            for (const scenario of scenarioList) {
                newDatas[scenario.id] = scenario;
            }

            const order = state.order;
            const idList = createIdList(newDatas, order);

            state.loaded = true;
            state.scenarios = newDatas
            state.idList = idList
        },

        updateScenario(state: scenarioStateType, action: any) {
            const scenario = action.payload;
            const newDatas = { ...state.scenarios };

            newDatas[scenario.id] = scenario;

            state.scenarios = newDatas

            const idList = [...state.idList];
            if (!idList.includes(scenario.id)) {
                idList.push(scenario.id);
            }
            state.idList = idList;
        },

        updateScenarioOrder(state: scenarioStateType, action: any) {
            const order = action.payload;
            const idList = createIdList(state.scenarios, order);

            state.order = order;
            state.idList = idList;
        },

        deleteScenario(state: scenarioStateType, action: any) {
            const id = action.payload;
            const newDatas = { ...state.scenarios };
            delete newDatas[id];
            state.scenarios = newDatas;
            const idList = [...state.idList];
            const newIdList = idList.filter((e) => {
                if (e === id) {
                    return false;
                } else {
                    return true;
                }
            });
            state.idList = newIdList;
        },

        clearScenario(state: scenarioStateType) {
            state = {
                loaded: false,
                scenarios: {},
                idList: [],
                order: [],
            }
        },
    },
});

// Reducer
export default slice;