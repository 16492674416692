

// material
import { MenuItem, TextField } from '@mui/material';
// redux
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { placeDataType } from 'src/@types/models/place';
import { changeCurrentPlaceLogic } from 'src/actions/logics/boot';
import { objectToArray } from 'src/utils/dataUtils';
// types
// ----------------------------------------------------------------------

type Props = {
    isCollapse?: boolean;
}

export default function PlacePopover({
    isCollapse
}: Props) {
    const { currentPlace, places, order } = useSelector((state: RootState) => state.place);
    const placeList = objectToArray(places, order);

    if (currentPlace == null || places == null) {
        return <div />
    }

    const onSelect = (event: any) => {
        const name = event.target.value;
        const selected = placeList.find((element: placeDataType) => {
            return element.name === name;
        })
        changeCurrentPlaceLogic(selected.id);
    };

    return (
        <TextField
            sx={{
                display: "flex"
            }}
            select
            size="small"
            value={currentPlace.name}
            onChange={onSelect}>
            {placeList.map((option: placeDataType) => (
                <MenuItem key={option.id} value={option.name}>
                    {option.name}
                </MenuItem>
            ))}
        </TextField>
    );
}