import {
    Breakpoint,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";

export type DialogButtonProps = {
    title: string;
    onClick: React.MouseEventHandler;
    color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
}

type Props = {
    open: boolean;
    onClose: React.MouseEventHandler;

    maxWidth: Breakpoint;

    title: string;
    subTitle?: string;
    buttonDatas?: DialogButtonProps[]
}

const DialogView = (props: Props) => {
    const {
        open,
        onClose,
        maxWidth,
        title,
        subTitle,
        buttonDatas
    } = props;

    return (
        <Dialog
            open={open}
            onClose={onClose}
            transitionDuration={200}
            fullWidth
            maxWidth={maxWidth}
        >
            <DialogTitle
                id={"tip-dialog-title"}
                sx={{ pb: 2 }}
            >
                {title}
            </DialogTitle>
            {
                subTitle &&
                <DialogContent>
                    <DialogContentText variant={"subtitle2"} sx={{whiteSpace : "pre-wrap"}}>
                        {subTitle}
                    </DialogContentText>
                </DialogContent>
            }
            {
                buttonDatas &&
                <DialogActions>
                    {
                        buttonDatas.map((button, idx) => (
                            <Button
                                key={idx}
                                size={"large"}
                                color={button.color ? button.color : "inherit"}
                                onClick={button.onClick}
                            >
                                {button.title}
                            </Button>
                        ))
                    }
                </DialogActions>
            }
        </Dialog>
    )
}

export default DialogView;