// @mui
import { Container, Typography, Box } from '@mui/material';
// components
import SocialsButton from 'src/components/organisms/SocialsButton';
import Logo from 'src/components/organisms/Logo';
import useResponsive from 'src/hooks/useResponsive';
import packageJson from '../../../package.json';

// ----------------------------------------------------------------------

const Terms = [
  {
    name: '이용약관',
    href: "https://www.clomount.com/policy_kor"
  },
  {
    name: '개인정보 처리방침',
    href: "https://www.clomount.com/privacy_kor"
  },
  {
    name: `${packageJson.version}(${packageJson.buildNumber})`,
    href: ""
  },
];

const Info = [
  {
    name: '서울특별시 금천구 벚꽃로 254, 308-1호',
  },
  {
    name: 'marketing@clomount-co.com',
  },
]


// ----------------------------------------------------------------------

export default function MainFooter() {
  const isMdUp = useResponsive('up', 'md');

  return (
    <Box
      sx={{
        pb: { xs: 0.5, sm: 14 },
        pt: { xs: 1, sm: 7 },
        background: '#F9F8FC',
      }}
    >
      <Container maxWidth={'xl'}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'start',
            justifyContent: 'space-between',
            mb: { xs: 3, sm: 0 },
          }}
        >
          <Box sx={{ mt: 1 }}>
            <Logo
              sx={{ width: isMdUp ? 120 : 75 }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end'
            }}
          >
            {
              Terms.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  style={{
                    textDecoration: 'none',
                    color: 'GrayText',
                    padding: 8,
                    paddingRight: 0,
                    fontSize: 14,
                    paddingLeft: 16
                  }}
                >
                  {item.name}
                </a>
              ))
            }
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: { xs: 'start', sm: 'center' },
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: { xs: 'start', sm: 'flex-end' },
            flexWrap: 'wrap',
            mb: { xs: 3, sm: 7 }
          }}
        >

          {
            Info.map((item) => (
              <Box
                key={item.name}
                sx={{
                  ml: { sm: 2 }
                }}
              >
                <Typography
                  style={{
                    color: 'GrayText',
                    fontSize: 14,
                  }}
                >
                  {item.name}
                </Typography>
              </Box>
            ))
          }
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
          }}
        >
          <Typography
            component="p"
            variant="body2"
            sx={{
              fontSize: 14,
              color: 'GrayText',
            }}
          >
            2022 © Clomount All Rights Reserved.
          </Typography>
          <SocialsButton />
        </Box>
      </Container>
    </Box>
  );
}
