import axios from 'axios';
import { refreshTokenData } from './axios_auth';
// config
import { HOST_API } from '../config';
import { getSession } from './jwt';
import { getMessge } from './message';
// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: HOST_API,
});

const pkg = require("../../package.json");
axiosInstance.defaults.headers.common.pv = pkg.buildNumber;
axiosInstance.defaults.headers.common.po = 'web';

axiosInstance.interceptors.response.use(
  (response) => response.data,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      console.log("token refresh start!");
      originalRequest._retry = true;
      try {
        var { refreshToken } = getSession();
        const tokens: any = await refreshTokenData(refreshToken!)
        setAuthorization(tokens.accessToken);
        originalRequest.headers.Authorization = tokens.accessToken;
        return axiosInstance(originalRequest);
      } catch (error) {
        console.log("refreshToken error: ", error);
        console.log("세션만료 - 재로그인 필요");
        return Promise.reject(error);
      }
    } else if (error.response.status === 401 && originalRequest._retry) {
      console.log("retry fail");
    }
    if (error.response != null && error.response.data != null) {
      console.log("axios error: ", error.response.data);
      const { code } = error.response.data;
      error.response.data.message = getMessge(code);
    }
    return Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    );
  }
);

export const setAuthorization = (accessToken: string) => {
  axiosInstance.defaults.headers.common.Authorization = accessToken;
}

export default axiosInstance;
