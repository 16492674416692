//? ==== components ====
import { Box, SxProps } from "@mui/material";

type Props = {
    sx?: SxProps;

    src: string;
    fill?: string;

    iconSize?: string | number;

    onClick?: React.MouseEventHandler;
}

export default function FillSvgIcon(props: Props) {
    const {
        sx,
        fill,
        src,
        iconSize,
        onClick
    } = props;

    return (
        <Box
            component="span"
            sx={{
                width: iconSize,
                height: iconSize,
                display: 'inline-block',
                bgcolor: fill || "currentcolor",
                mask: `url(${src}) no-repeat center / contain`,
                WebkitMask: `url(${src}) no-repeat center / contain`,
                cursor: onClick ? "pointer" : "default",

                "&:hover": {
                    opacity: onClick ? 0.5 : 1
                },
                "&:active": {
                    opacity: onClick ? 0.2 : 1
                },
                ...sx,
            }}
            onClick={onClick}
        />
    )
}