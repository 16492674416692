import { respSuccess } from "src/@types/api";
import {
    placeDataType,
    placeDataTypeForCreate,
    placeDetailType,
} from "../../@types/models/place";
import * as api from "../apis/place";
import * as storage from "../../utils/storage";
import dispatchers from "src/redux/dispatchers";

export const queryPlace = (u_id: string) => {
    return new Promise<placeDataType[]>(async (resolve: any, reject) => {
        try {
            const res: respSuccess = await api.queryPlace(u_id);
            dispatchers.place.selectPlace(res.data);
            resolve(res.data);
        } catch (error) {
            console.log("queryPlace error: ", error);
            reject(error);
        }
    });
};

export const getPlace = (id: string) => {
    return new Promise<placeDataType>(async (resolve: any, reject) => {
        try {
            const res: respSuccess = await api.getPlace(id);
            dispatchers.place.updatePlace(res.data);
            console.log("getPlace res: ", res);
            resolve(res.data);
        } catch (error) {
            console.log("getPlace error: ", error);
            reject(error);
        }
    });
};

export const getPlaceDetail = (id: string) => {
    return new Promise<placeDetailType>(async (resolve: any, reject) => {
        try {
            const places = storage.getPlaceOrders();
            const {
                devices, scenarios, rooms, links
            } = storage.getOrders(id);

            dispatchers.place.updatePlaceOrder(places);
            dispatchers.device.updateDeviceOrder(devices);
            dispatchers.room.updateRoomOrder(rooms);
            dispatchers.scenario.updateScenarioOrder(scenarios);
            dispatchers.link.updateLinkOrder(links);

            const res: respSuccess = await api.getPlaceDetail(id);
            const {
                deviceList,
                scenarioList,
                roomList,
                linkList,
            }: placeDetailType = res.data;
            for (const device of deviceList) {
                Object.assign(device, device.deviceData);
            }
            dispatchers.device.selectDevice([...deviceList])
            dispatchers.room.selectRoom([...roomList])
            dispatchers.scenario.selectScenario([...scenarioList])
            dispatchers.link.selectLink([...linkList])
            resolve(res.data);
        } catch (error) {
            console.log("getPlaceDetail error: ", error);
            reject(error);
        }
    });
};

export const createPlace = (place: placeDataTypeForCreate) => {
    return new Promise<placeDataType>(async (resolve: any, reject) => {
        try {
            const res: respSuccess = await api.createPlace(place);
            dispatchers.place.updatePlace(res.data.place);
            resolve(res.data.place);
        } catch (error) {
            console.log("createPlace error: ", error);
            reject(error);
        }
    });
};

export const updatePlace = (id: string, place: any) => {
    return new Promise<placeDataType>(async (resolve: any, reject) => {
        try {
            const res: respSuccess = await api.updatePlace(id, place);
            dispatchers.place.updatePlace(res.data);
            resolve(res.data);
        } catch (error) {
            console.log("updatePlace error: ", error);
            reject(error);
        }
    });
};

export const deletePlace = (id: string) => {
    return new Promise<string>(async (resolve: any, reject) => {
        try {
            const res: respSuccess = await api.deletePlace(id);
            dispatchers.place.deletePlace(id);
            resolve(id);
        } catch (error) {
            console.log("deletePlace error: ", error);
            reject(error);
        }
    });
};
