import { createSlice } from "@reduxjs/toolkit";
import { deviceDataType, deviceIdRoomIdType, deviceStateType } from "src/@types/models/device";
import { objectToArray } from "src/utils/dataUtils";
const pkg = require("../../../package.json");

const initialState: deviceStateType = {
    loaded: false,
    idList: [],
    devices: {},
    order: [],
};

const createIdList = (devices: any, order: string[]): deviceIdRoomIdType[] => {
    const idList: deviceIdRoomIdType[] = [];
    const tempArr = objectToArray(devices, order);
    for (const device of tempArr) {
        idList.push({
            id: device.id,
            r_id: device.r_id
        });
    }
    return idList;
}

const convert = (devices: Record<string, deviceDataType>) => {
    const deviceList: deviceDataType[] = objectToArray(devices);
    for (const key in devices) {
        const device = devices[key];
        /*
            프론트엔드 버전에 따라 sptv 설정할 수 있도록 조치F
        */
        (device as any).pv = pkg.buildNumber;
        (device as any).po = 'web';
    }
    const gwDeviceList = deviceList.filter((e) => e.productBean && e.productBean.isCanGateway);
    for (const gwDevice of gwDeviceList) {
        const { supportGatewayProtocols } = gwDevice.productBean;
        const protocols = supportGatewayProtocols || [];
        for (const protocol of protocols) {
            const nodes = deviceList.filter((e) => e.h_id == gwDevice.id && e.productBean && e.productBean.mainProtocol == protocol);
            const target: any = devices[gwDevice.id];
            target[`gw_${protocol}_num`] = nodes.length;
        }
    }

    return devices;
}

const slice = createSlice({
    name: 'device',
    initialState,
    reducers: {
        selectDevice(state: deviceStateType, action: any) {
            const newDevices: Record<string, deviceDataType> = {};
            const deviceList: deviceDataType[] = action.payload;

            const order = state.order;
            for (const device of deviceList) {
                newDevices[device.id] = device;
            }

            const idList = createIdList(newDevices, order);
            state.loaded = true;
            state.devices = convert(newDevices);
            state.idList = idList;
        },

        updateDevice(state: deviceStateType, action: any) {
            const device = action.payload;
            const newDevices = { ...state.devices };
            newDevices[device.id] = device;
            state.devices = convert(newDevices);

            const idList: deviceIdRoomIdType[] = [...state.idList];

            const index = idList.findIndex(x => x.id === device.id);

            if (index >= 0) {
                if (idList[index].r_id !== device.r_id) {
                    idList[index].r_id = device.r_id;
                    state.idList = [...idList];
                }
            } else {
                idList.push({
                    id: device.id,
                    r_id: device.r_id
                });
            }
            state.idList = [...idList];
        },

        updateDeviceOrder(state: deviceStateType, action: any) {
            const order = action.payload;
            const idList = createIdList(state.devices, order);

            state.order = order
            state.idList = idList
        },

        deleteDevice(state: deviceStateType, action: any) {
            const id = action.payload;
            const newDevices = { ...state.devices };
            delete newDevices[id];

            state.devices = convert(newDevices);
            const idList: deviceIdRoomIdType[] = [...state.idList];
            const newIdList = idList.filter((e) => {
                if (e.id === id) {
                    return false;
                } else {
                    return true;
                }
            });
            state.idList = [...newIdList];
        },

        clearDevice(state: deviceStateType) {
            state = {
                loaded: false,
                devices: {},
                idList: [],
                order: [],
            }
        },
    },
});

// Reducer
export default slice;