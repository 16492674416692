import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
// @mui
import { Box, BoxProps } from '@mui/material';
import Image from '../atoms/Image';
import useSettings from 'src/hooks/useSettings';
import useIsLight from 'src/hooks/useIsLight';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  isSymbol?: boolean;
  disabledLink?: boolean;
}

export default function Logo({ disabledLink = false, isSymbol = false, sx }: Props) {
  const { isLight } = useIsLight();

  const logo = (
    <Box sx={{
      width: 180,
      ...sx
    }}>
      <Image
        alt={isSymbol ? "logo_symbol" : "logo"}
        src={isSymbol ? "/logo/logo_symbol_tech.png" : (isLight ? "/logo/logo_text_black.png" : "/logo/logo_vertical_white.png")}
      />
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
