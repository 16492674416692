//? ==== routes ====
import Router from './routes';

//? ==== theme ====
import ThemeProvider from './theme';

//? ==== components ====
import RtlLayout from './providers/RtlLayout';
import ScrollToTop from './providers/ScrollToTop';
import { ProgressBarStyle } from './providers/ProgressBar';
import NotistackProvider from './providers/NotistackProvider';
import ThemeColorPresets from './providers/ThemeColorPresets';
import ThemeLocalization from './providers/ThemeLocalization';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import { StyledEngineProvider } from '@mui/material/styles';
import GoogleAnalytics from './analytics/GoogleAnalytics';
import Settings from './components/settings';
import { useEffect } from 'react';
import { checkAndSetLanguages, loadAndSetLanguages } from './actions/logics/language';
import { initTranslate } from './utils/language';

// ----------------------------------------------------------------------

export default function App() {

  useEffect(() => {
    initTranslate();
    checkLanguages();
  }, []);

  const checkLanguages = async () => {
    try {
      await loadAndSetLanguages();
    } catch (error) {

    }
    try {
      await checkAndSetLanguages();
    } catch (error) {

    }
  };

  return (
    <ThemeProvider>
      <StyledEngineProvider injectFirst>
        <ThemeColorPresets>
          <ThemeLocalization>
            <RtlLayout>
              <NotistackProvider>
                <MotionLazyContainer>
                  <ProgressBarStyle />
                  {/* <ChartStyle /> */}
                  <GoogleAnalytics />
                  <Settings />
                  <ScrollToTop />
                  <Router />
                </MotionLazyContainer>
              </NotistackProvider>
            </RtlLayout>
          </ThemeLocalization>
        </ThemeColorPresets>
      </StyledEngineProvider>
    </ThemeProvider>
  );
}
