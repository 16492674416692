import { Breakpoint } from "@mui/material";
import React, { ReactNode, useEffect, useState } from "react";
import DialogView, { DialogButtonProps } from "src/components/atoms/DialogView";

export type DialogContextProps = {
    openDialog: (title: string, subtitle?: string, buttonDatas?: DialogButtonProps[], maxWidth?: Breakpoint) => void;
    closeDialog: () => void;
}

const initialState: DialogContextProps = {
    openDialog: () => { },
    closeDialog: () => { }
}

const DialogContext = React.createContext(initialState);

type DialogProviderProps = {
    children: ReactNode;
}

function DialogProvider({ children }: DialogProviderProps) {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const [title, setTitle] = useState<string>();
    const [subtitle, setSubtitle] = useState<string | undefined>(undefined);
    const [buttonDatas, setButtonDatas] = useState<DialogButtonProps[] | undefined>(undefined);

    const [maxWidth, setMaxWidth] = useState<Breakpoint>("sm");

    useEffect(() => {
        if (title !== undefined) {
            setIsOpen(true);
        }
    }, [title]);

    return (
        <DialogContext.Provider
            value={{
                openDialog: (title, subtitle, buttonDatas, maxWidth) => {
                    setIsOpen(true);
                    setTitle(title);
                    setSubtitle(subtitle);
                    setButtonDatas(buttonDatas);
                    setMaxWidth(maxWidth || "sm");
                },
                closeDialog: () => {
                    setIsOpen(false);

                    const out = setTimeout(() => {
                        setTitle(undefined);
                        setSubtitle(undefined);
                        setButtonDatas(undefined);
                    }, 200);

                    clearTimeout(out);
                }
            }}
        >
            {children}
            {
                title &&
                <DialogView
                    open={isOpen}
                    onClose={() => setIsOpen(false)}
                    title={title}
                    subTitle={subtitle}
                    buttonDatas={buttonDatas}
                    maxWidth={maxWidth}
                />
            }
        </DialogContext.Provider>
    )
}

export { DialogProvider, DialogContext };
