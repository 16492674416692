//? ==== hooks ====
import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

//? ==== utils ====
import dispatchers from 'src/redux/dispatchers';
import { PATH_PAGE } from 'src/routes/paths';

type RoleBasedGuardProp = {
  accessibleRoles?: string[];
  children?: ReactNode | string;
};

export default function RoleBasedGuard({ accessibleRoles, children }: RoleBasedGuardProp) {
  let userRole: string = "";
  const user = dispatchers.user.getUserData();
  if (user) {
    userRole = user.role || "";
  }

  const isRoleContent = userRole.includes('content') || false

  if (!isRoleContent) {
    return (
      <Navigate to={PATH_PAGE.page404} />
    )
  }

  return (
    <React.Fragment>
      {children}
    </React.Fragment>
  );
}
