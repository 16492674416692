import axios from "axios";
import { changeLanguageData, getLanguageVersionNumber, langDataType } from "../../utils/language";
import { respSuccess } from "../../@types/api";
import * as apis from "../apis/languages";
import * as storage from "../../utils/storage";

export function loadAndSetLanguages() {
  return new Promise<langDataType | undefined>(async (resolve: any, reject) => {
    try {
      const stored = await storage.getLangData();
      if (stored != undefined && stored.version != undefined) {
        if (stored.versionNumber > getLanguageVersionNumber()) {
          changeLanguageData(stored);
        }
        resolve(stored);
        return;
      }
      resolve(undefined);
    } catch (error) {
      reject(error);
    }
  })
}

export function checkAndSetLanguages() {
  return new Promise<langDataType | undefined>(async (resolve: any, reject) => {
    try {
      const res: respSuccess = await apis.checkAndGetLanguages(getLanguageVersionNumber());
      if (res.data != undefined && res.data.url != undefined) {
        const url = res.data.url;
        const fetched = await axios.get(url);
        const langData: any = fetched.data;
        console.log('fetched langData: ', langData);
        changeLanguageData(langData);
        storage.saveLangData(langData);
        resolve(langData);
        return;
      }
      resolve(undefined);
    } catch (error) {
      console.log("getSystemLanguages error: ", error);
      reject(error);
    }
  });
}
