import { createSlice } from "@reduxjs/toolkit";
import { userDataType } from "src/@types/models/user";

type userStateType = {
    userData?: userDataType;
}

const initialState: userStateType = {
    userData: undefined,
};

const slice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        selectUser(state: userStateType, action: any) {
            const userData: userDataType = action.payload;
            state.userData = userData;
        },

        updateUser(state: userStateType, action: any) {
            const userData: userDataType = action.payload;
            state.userData = userData;
        },

        clearUser(state: userStateType) {
            state = {
                userData: undefined
            }
        },
    },
});

// Reducer
export default slice;