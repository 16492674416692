import { useTranslation } from 'react-i18next';
// @mui
import { enUS, koKR } from '@mui/material/locale';
import queryString from "query-string";

// ----------------------------------------------------------------------

const LANGS = [
  {
    label: 'Korea',
    value: 'kr',
    systemValue: koKR,
    icon: 'https://minimal-assets-api.vercel.app/assets/icons/ic_flag_kr.svg',
  },
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
    icon: 'https://minimal-assets-api.vercel.app/assets/icons/ic_flag_en.svg',
  },
];

export default function useLocales() {
  const { i18n, t: translate } = useTranslation();
  const langStorage = localStorage.getItem('i18nextLng');
  const query = queryString.parse(window.location.search) || {};
  const currentLang =
    LANGS.find((_lang) => _lang.value === query.lang) ||
    LANGS.find((_lang) => _lang.value === langStorage) ||
    LANGS[0];

  const handleChangeLanguage = (newlang: string) => {
    i18n.changeLanguage(newlang);
  };

  return {
    onChangeLang: handleChangeLanguage,
    translate,
    currentLang: currentLang,
    allLang: LANGS,
  };
}
