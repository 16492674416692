import { Box } from '@mui/material'
// components
import Iconify from 'src/components/atoms/Iconify'
import { sendToPlaitAppMoveToGoBack } from 'src/utils/webViewPostMessage'

export const AppHeader = () => {
    return (
        <Box sx={{
            position: 'fixed',
            width: '100%',
            top: 0,
            left: 0,
            height: 52,
            pl: 2,
            background: 'white',
            borderBottom: '1px solid rgba(0,0,0,0.1)',
            display: 'flex',
            alignItems: 'center',
            zIndex: 1
        }}>
            <Iconify
                onClick={sendToPlaitAppMoveToGoBack}
                icon="akar-icons:chevron-left"
                width={24}
                height={24}
                style={{
                    cursor: 'pointer',
                    color: 'gray',
                }}
            />
        </Box>
    )
}
