//? ==== routes ====
import { PATH_CONTENT, PATH_DASHBOARD, PATH_STORE } from 'src/routes/paths';

//? ==== components ====
import Iconify from 'src/components/atoms/Iconify';
import { MenuItemProps } from 'src/@types/props/menu';

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22,
};

const menuConfig: MenuItemProps[] = [
  {
    title: '콘텐츠',
    icon: <Iconify icon={'eva:home-fill'} {...ICON_SIZE} />,
    path: PATH_CONTENT.root,
  },
  {
    title: '대시보드',
    icon: <Iconify icon={'eva:book-open-fill'} {...ICON_SIZE} />,
    path: PATH_DASHBOARD.root,
  },
  {
    title: '스토어',
    icon: <Iconify icon={'eva:book-open-fill'} {...ICON_SIZE} />,
    path: PATH_STORE.root,
  },
];

export default menuConfig;
