import * as api from "../../apis/life";
import * as storage from "../../../utils/storage";
import { respSuccess } from "src/@types/api";
import dispatchers from "../../../redux/dispatchers";
import {
    lifeDataCreateType,
    lifeDataType,
    lifeDataUpdateType,
    lifeThemeDetailType,
    lifeThemeType
} from "../../../@types/models/life";

export const queryLifeThemes = (p_id: string) => {
    return new Promise<lifeThemeType[]>(async (resolve: any, reject) => {
        try {
            const res: respSuccess = await api.queryLifeThemes(p_id);
            const themes: lifeThemeType[] = res.data;
            resolve(themes);
        } catch (error) {
            console.log("queryLifeTheme error: ", error);
            reject(error);
        }
    });
};

export const getLifeThemeDetail = (theme: string) => {
    return new Promise<lifeThemeDetailType>(async (resolve: any, reject) => {
        try {
            const res: respSuccess = await api.getLifeThemeDetail(theme);
            resolve(res.data);
        } catch (error) {
            console.log("getLifeThemeDetail error: ", error);
            reject(error);
        }
    });
};

export const getLife = (life_id: string) => {
    return new Promise<lifeDataType>(async (resolve: any, reject) => {
        try {
            const life = await api.getLife(life_id);
            if (life) {
                resolve(life.data);
            }
        } catch (error) {
            console.log("getLife error: ", error);
            reject(error);
        }
    })
}

export const queryLife = (p_id: string) => {
    return new Promise<lifeDataType[]>(async (resolve: any, reject) => {
        try {
            const {
                lifes
            } = storage.getOrders(p_id);
            dispatchers.life.updateLifeOrder(lifes);
            const res: respSuccess = await api.queryLife(p_id);
            const lifeList: lifeDataType[] = res.data;
            dispatchers.life.selectLife([...lifeList]);
            resolve(lifeList);
        } catch (error) {
            console.log("queryLife error: ", error);
            reject(error);
        }
    });
};

export const createLife = (item: lifeDataCreateType) => {
    return new Promise<lifeDataType[]>(async (resolve: any, reject) => {
        try {
            const res: respSuccess = await api.createLife(item);
            dispatchers.life.updateLife({ ...res.data });

            resolve(res.data);
        } catch (error) {
            console.log("createLife error: ", error);
            reject(error);
        }
    });
}

export const updateLife = (life_id: string, item: lifeDataUpdateType) => {
    return new Promise<lifeDataType[]>(async (resolve: any, reject) => {
        try {
            const res: respSuccess = await api.updateLife(life_id, item);
            dispatchers.life.updateLife({ ...res.data });
            resolve(res.data);
        } catch (error) {
            console.log("updateLife error: ", error);
            reject(error);
        }
    });
}

export const deleteLife = (life_id: string) => {
    return new Promise<lifeDataType[]>(async (resolve: any, reject) => {
        try {
            const res: respSuccess = await api.deleteLife(life_id);
            dispatchers.life.deleteLife(life_id);

            resolve(res.data);
        } catch (error) {
            console.log("deleteLife error: ", error);
            reject(error);
        }
    });
}