//? ==== hooks ====
import React, { ReactNode } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';

//? ==== types ====
import { MenuProps, MenuItemProps } from 'src/@types/props/menu';

//? ==== components ====
import {
  Link,
  Stack,
  LinkProps,
  styled,
} from '@mui/material';
import { PATH_STORE, PATH_DASHBOARD, PATH_CONTENT } from 'src/routes/paths';
import useEnvironment from 'src/hooks/useEnvironment';
import useRoot from 'src/hooks/useRoot';

// ----------------------------------------------------------------------

interface RouterLinkProps extends LinkProps {
  component?: ReactNode;
  to?: string;
  end?: boolean;
}

const LinkStyle = styled(Link)<RouterLinkProps>(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.primary,
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),

  '&:hover': {
    opacity: 0.5,
    textDecoration: 'none',
  },
}));

// ----------------------------------------------------------------------

export default function MenuDesktop({ isOffset, isHome, navConfig }: MenuProps) {

  return (
    <Stack direction="row">
      {
        navConfig.map((link) => (
          <MenuDesktopItem
            key={link.title}
            item={link}
            isOffset={isOffset}
            isHome={isHome}
          />
        ))
      }
    </Stack>
  );
}

// ----------------------------------------------------------------------

type MenuDesktopItemProps = {
  item: MenuItemProps;
  isHome: boolean;
  isOffset: boolean;
};

function MenuDesktopItem({
  item,
  isHome,
  isOffset,
}: MenuDesktopItemProps) {
  const { hardware } = useEnvironment();
  const { title, path, icon } = item;

  if (path === PATH_STORE.root) {
    if (hardware == "mobile") return <div />
    return (
      <LinkStyle
        href={path}
        target="_blank"
        rel="noopener"
        sx={{
          mr: { xs: 3, md: 5 },
          ...(isHome && { color: 'common.white' }),
          ...(isOffset && { color: 'text.primary' }),
          '&.active': {
            color: 'primary.lighter',
          },
        }}
      >
        {title}
      </LinkStyle>
    );
  }

  return (
    <LinkStyle
      to={path}
      component={RouterLink}
      end={path === '/'}
      sx={{
        mr: { xs: 3, md: 5 },
        ...(isHome && { color: 'common.white' }),
        ...(isOffset && { color: 'text.primary' }),
        '&.active': {
          color: 'primary.lighter',
        },
      }}
    >
      {title}
    </LinkStyle>
  );
}
