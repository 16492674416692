import { useEffect, useState } from 'react'
import MoveTopIcon from 'src/assets/icon_move_top';

export default function MoveTopBtn() {
    const [BtnStatus, setBtnStatus] = useState(false);

    useEffect(() => {
        const watch = () => {
            window.addEventListener('scroll', handleFollow)
        }
        watch();
        return () => {
            window.removeEventListener('scroll', handleFollow)
        }
    })

    const handleFollow = () => {
        if (100 <= window.pageYOffset) {
            setBtnStatus(true);
        } else {
            setBtnStatus(false);
        }
    }

    const handleTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    return BtnStatus ? (
        <MoveTopIcon
            sx={{
                position: 'fixed',
                bottom: "5%",
                right: "3%",
                zIndex: 10,
                height: 60,
                width: 60,
                cursor: "pointer",
            }}
            onClick={handleTop}
        />
    ) : null
};