import { getLanguageTag } from "./language";

export function checkKorean() {
  return getLanguageTag() == 'kr';
}

export function regEmail(email: string) {
  const regEmail = /^([\w\.\_\-])*[a-zA-Z0-9]+([\w\.\_\-])*([a-zA-Z0-9])+([\w\.\_\-])+@([a-zA-Z0-9\w\.\_\-]+\.)+[a-zA-Z0-9]{2,8}$/i;

  return regEmail.test(email)
}

export function regPassword(password: string) {
  const regPassword = /^.*(?=^.{8,15}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"£¥•]).*$/;

  return regPassword.test(password);
}

export const checkMobile = () => {
  const varUA = navigator.userAgent.toLowerCase();
  if (varUA.indexOf('android') > -1) {
    return "android";
  } else if (varUA.indexOf("iphone") > -1 || varUA.indexOf("ipad") > -1 || varUA.indexOf("ipod") > -1) {
    return "ios";
  } else {
    return "other";
  }
}

export const base64EncodeString = (data: string) => {
  return Buffer.from(data).toString('base64');
}


export const checkValidString = (value?: string) => {
  if (value === "" || value === undefined) {
    return true;
  }

  return false;
};

export const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

export const isEmpty = (obj: object) => Object.keys(obj).length === 0;

export const checkDate = (date: any) => {
  if (date != null && !isNaN(new Date(date).getTime()) && new Date(date).getTime() > 0) {
    return true;
  }
  return false;
}

export const objectCloneDeep = (object: any) => {
  return JSON.parse(JSON.stringify(object));
};

export const objectToArray = (objects: any, order?: string[]): any[] => {
  const array: any[] = [];
  const orderArray: any[] = [];

  if (order === undefined) {
    for (const key in objects) {
      if (objects[key] !== undefined) {
        array.push(objects[key]);
      }
    }
    return array;
  }

  for (const key of order) {
    if (objects[key] !== undefined) {
      orderArray.push(objects[key]);
    }
  }

  for (const key in objects) {
    const index = orderArray.findIndex((e) => {
      if (e.id === key) return true;
    })
    if (index > -1) continue;
    orderArray.push(objects[key]);
  }

  return orderArray;
};

export const createIdList = (data: any, order: string[]): string[] => {
  const idList: string[] = [];
  const tempArr = objectToArray(data, order);
  for (const object of tempArr) {
    idList.push(object.id);
  }
  return idList;
}

export function getRandomNumber() {
  return Math.floor(Math.random() * 2100000000) + 1;
}

export const getFirstLastDay = () => {
  const date = new Date();

  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);

  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  return [firstDay.getDate(), lastDay.getDate()]
}

export function priceFormat(price: number | string) {
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const getPrice = (data: number[]) => {
  const sum = data.reduce((acc, v) => { return acc + v }, 0)
  const primary = getPrimaryPrice(sum)
  const power = getPowerPrice(sum)

  return Math.round(primary + power)
}

export const getPrimaryPrice = (sum: number) => {
  // 22년 4월 1일 이후 적용 주택용 전력 (고압)
  // https://cyber.kepco.co.kr/ckepco/front/jsp/CY/E/E/CYEEHP00101.jsp
  // [1구간, 2구간, 3구간]
  const PRIMARY_PRICE_LIST = [730, 1260, 6060]
  const thisMonth = new Date().getMonth() + 1

  // 하계 요금 적용
  if (thisMonth === 7 || thisMonth === 8) {
    if (sum <= 300) {
      return PRIMARY_PRICE_LIST[0]
    }
    if (sum <= 450) {
      return PRIMARY_PRICE_LIST[1]
    }
    return PRIMARY_PRICE_LIST[2]
  } else {
    if (sum <= 200) {
      return PRIMARY_PRICE_LIST[0]
    }
    if (sum <= 400) {
      return PRIMARY_PRICE_LIST[1]
    }
    return PRIMARY_PRICE_LIST[2]
  }
}

export const getPowerPrice = (sum: number) => {
  // 22년 4월 1일 이후 적용 주택용 전력 (고압)
  // https://cyber.kepco.co.kr/ckepco/front/jsp/CY/E/E/CYEEHP00101.jsp
  // [1구간, 2구간, 3구간]
  const POWER_PRICE_LIST = [78.2, 147.2, 215.5]
  const thisMonth = new Date().getMonth() + 1

  // 하계 요금 적용
  if (thisMonth === 7 || thisMonth === 8) {
    if (0 <= sum && sum <= 300) {
      return sum * POWER_PRICE_LIST[0]
    } else if (300 < sum && sum <= 450) {
      return (300 * POWER_PRICE_LIST[0]) + ((sum - 300) * POWER_PRICE_LIST[1])
    } else {
      return (300 * POWER_PRICE_LIST[0]) + (150 * POWER_PRICE_LIST[1]) + ((sum - 450) * POWER_PRICE_LIST[2])
    }
  } else {
    if (0 <= sum && sum <= 200) {
      return sum * POWER_PRICE_LIST[0]
    } else if (200 < sum && sum <= 400) {
      return (200 * POWER_PRICE_LIST[0]) + ((sum - 200) * POWER_PRICE_LIST[1])
    } else {
      return (200 * POWER_PRICE_LIST[0]) + (200 * POWER_PRICE_LIST[1]) + ((sum - 400) * POWER_PRICE_LIST[2])
    }
  }
}

export function isNumeric(str: string) {
  if (typeof str != "string") return false // we only process strings!  
  return !isNaN(str as any) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}