import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import queryString from "query-string";

export type langDataType = {
    version: string;
    versionNumber: number;
    languages: {
        ko: any,
        en: any,
        jp: any,
        zh: any,
    }
}

let data = require("../data/langs.json");
const query = queryString.parse(window.location.search) || {};
const lng = query.lang || localStorage.getItem('i18nextLng') || 'ko' as any
// const lng = 'ko';

const initTranslate = () => {
    i18n
        .use(LanguageDetector)
        .use(initReactI18next)
        .init({
            resources: {
                ko: { translations: data.languages.ko },
                en: { translations: data.languages.en },
                ja: { translations: data.languages.ja },
                zh: { translations: data.languages.zh },
            },
            lng,
            fallbackLng: 'en',
            debug: false,
            ns: ['translations'],
            defaultNS: 'translations',
            interpolation: {
                escapeValue: false
            }
        });
};

const translate = (key: string) => {
    return i18n.t(key, { returnObjects: true });
}

const getLanguageTag = () => {
    return i18n.language;
}

const getLanguageVersion = () => {
    return data.version;
}

const getLanguageVersionNumber = () => {
    return data.versionNumber;
};

const changeLanguageData = (changed: langDataType) => {
    data = changed;
    initTranslate();
}

export {
    i18n,
    translate,
    getLanguageTag,
    getLanguageVersion,
    getLanguageVersionNumber,
    changeLanguageData,
    initTranslate
};