import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
// slices
import user from './slices/user';
import place from './slices/place';
import room from './slices/room';
import device from './slices/device';
import scenario from './slices/scenario';
import link from './slices/link';
import life from './slices/life';
import system from './slices/system';
// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const rootReducer = combineReducers({
  user: user.reducer,
  place: place.reducer,
  room: room.reducer,
  device: device.reducer,
  scenario: scenario.reducer,
  link: link.reducer,
  life: life.reducer,
  system: system.reducer,
});

export { rootPersistConfig, rootReducer };
