// hooks
import useAuth from '../../hooks/useAuth';
// utils
import createAvatar from '../../utils/createAvatar';
//
import Avatar, { Props as AvatarProps } from '../molecules/Avatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }: AvatarProps) {
  const { user } = useAuth();

  return (
    <Avatar
      src={user?.profileImg}
      alt={user?.name}
      color={user?.picture ? 'default' : createAvatar(user?.displayName).color}
      {...other}
    >
      {createAvatar(user?.name).name}
    </Avatar>
  );
}
