import { RootType } from "src/hooks/useRoot";
import { langDataType } from "./language";

const attBootData = 'bootData';
const attOrderData = 'orderData'
const attImages = 'images';
const attAuthAfter = 'authAfter'
const attAuthLastState = 'authLastState';
const attLang = 'langData';
const attDeviceCustomCache = '-dcache';
const attAuthLastUrl = 'authLastUrl';

function save(key: string, data: string) {
    return localStorage.setItem(key, data);
}

function get(key: string) {
    return localStorage.getItem(key);
}

function remove(key: string) {
    return localStorage.removeItem(key);
}

/*

boot data cache

*/

type BootDataType = {
    currentPlaceId?: string;
}

var bootData: BootDataType = {};

export function loadBootData() {
    const res: string | null = get(attBootData);
    try {
        bootData = JSON.parse(res!);
    } catch (error) {
        bootData = {};
    }
    bootData = bootData || {};
    return bootData;
}

export function getBootData(): BootDataType {
    return bootData;
}

export function removeBootData() {
    bootData = {};
    remove(attBootData);
}

export function saveCurrentPlaceId(data: string) {
    bootData.currentPlaceId = data;
    return save(attBootData, JSON.stringify(bootData));
}


//====== Order ======

let orderData: any = {};

export function loadOrders() {
    const res: string | null = get(attOrderData)
    try {
        orderData = JSON.parse(res!);
    } catch (error) {
        orderData = {};
    }
    orderData = orderData || {};
    return orderData;
}

type orderDataType = {
    devices: string[];
    scenarios: string[];
    rooms: string[];
    links: string[];
    lifes: string[];
}

export function getPlaceOrders(): string[] {
    orderData["places"] = orderData["places"] || [];
    return orderData["places"];
}

export function getOrders(p_id: string): orderDataType {
    orderData[p_id] = orderData[p_id] || {};
    orderData[p_id].devices = orderData[p_id].devices || [];
    orderData[p_id].scenarios = orderData[p_id].scenarios || [];
    orderData[p_id].rooms = orderData[p_id].rooms || [];
    orderData[p_id].links = orderData[p_id].links || [];
    orderData[p_id].lifes = orderData[p_id].lifes || [];
    return orderData[p_id];
}

export function saveOrderPlace(order: string[]) {
    orderData["places"] = orderData["places"] || {};
    orderData["places"] = order;
    return save(attOrderData, JSON.stringify(orderData));
}

export function saveOrderDevice(p_id: string, order: string[]) {
    orderData[p_id] = orderData[p_id] || {};
    orderData[p_id].devices = order;
    return save(attOrderData, JSON.stringify(orderData));
}

export function saveOrderRoom(p_id: string, order: string[]) {
    orderData[p_id] = orderData[p_id] || {};
    orderData[p_id].rooms = order;
    return save(attOrderData, JSON.stringify(orderData));
}

export function saveOrderScenario(p_id: string, order: string[]) {
    orderData[p_id] = orderData[p_id] || {};
    orderData[p_id].scenarios = order;
    return save(attOrderData, JSON.stringify(orderData));
}

export function saveOrderLink(p_id: string, order: string[]) {
    orderData[p_id] = orderData[p_id] || {};
    orderData[p_id].links = order;
    return save(attOrderData, JSON.stringify(orderData));
}

export function saveOrderLife(p_id: string, order: string[]) {
    orderData[p_id] = orderData[p_id] || {};
    orderData[p_id].lifes = order;
    return save(attOrderData, JSON.stringify(orderData));
}

export function clear() {
    removeBootData();
}

/*

image cache

*/

var images: any = {};

export function saveSVG(uri: string, data: string) {
    images[uri] = data;
    return save(attImages, JSON.stringify(images));
}

export function getSVG(uri: string) {
    return images == undefined ? undefined : images[uri];
}

export function loadSVG() {
    return new Promise<any>(async (resolve, reject) => {
        try {
            const res: string | null = await get(attImages)
            try {
                images = JSON.parse(res!);
            } catch (error) {
                images = {};
            }
            images = images || {};
            if (images.expireDate == undefined ||
                new Date().getTime() - new Date(images.expireDate).getTime() > 0) {
                const expireDate = new Date();
                expireDate.setMonth(expireDate.getMonth() + 1);
                images = { expireDate: expireDate.toISOString() };
            }
            resolve(undefined)
        } catch (e) {
            resolve(undefined)
        }
    });
}

export function removeSVG(uri: string) {
    images[uri] = undefined;
    return save(attImages, JSON.stringify(images));
}

export function resetSVG() {
    images = {};
    return remove(attImages);
}

export function getAllSvgs() {
    return images;
}

export function getSvgExpireDate() {
    return images.expireDate;
}

/*

auth after

*/

export function saveAuthAfter(root: RootType) {
    return save(attAuthAfter, root);
}

export function getAuthAfter(): RootType | undefined {
    return get(attAuthAfter) as RootType;
}

export function saveAuthLastState(value: string) {
    return save(attAuthLastState, value);
}

export function getAuthLastState(): string | undefined {
    return get(attAuthLastState) as string;
}

export function saveAuthLastUrl(url: string) {
    return save(attAuthLastUrl, url);
}

export function getAuthLastUrl(): string | undefined {
    return get(attAuthLastUrl) as string;
}

export function removeAuthLastUrl() {
    return remove(attAuthLastUrl);
}

/*

language

*/

export function getLangData() {
    return new Promise<langDataType | undefined>(async (resolve, reject) => {
        try {
            var langs = undefined;
            const res: string | null = get(attLang);
            try {
                langs = JSON.parse(res!);
            } catch (error) {
                langs = undefined;
            }
            resolve(langs);
        } catch (e) {
            resolve(undefined)
        }
    });
}

export function saveLangData(lang: langDataType) {
    return save(attLang, JSON.stringify(lang));
}

/*

device cahce

*/

export function saveDeviceCache(id: string, data: any) {
    return save(`${id}${attDeviceCustomCache}`, JSON.stringify(data));
}

export function getDeviceCache(id: string) {
    return new Promise<any>(async (resolve, reject) => {
        const res: string | null = get(`${id}${attDeviceCustomCache}`);
        var data: any = {};
        try {
            if (JSON.parse(res!) != undefined) {
                data = JSON.parse(res!);
            }
        } catch (error) {
        }
        data = data || {};
        resolve(data);
    })
}
