import { roomDataType } from "../../@types/models/room";
import { store, dispatch } from "../store";
import { objectToArray } from "../../utils/dataUtils";
import slice from "../slices/room"

export const selectRoom = (roomList: roomDataType[]) => {
  dispatch(slice.actions.selectPlace(roomList));
};

export const updateRoom = (room: roomDataType) => {
  dispatch(slice.actions.updateRoom(room));
};

export const updateRoomOrder = (order: string[]) => {
  dispatch(slice.actions.updateRoomOrder(order));
}

export const deleteRoom = (id: string) => {
  dispatch(slice.actions.deleteRoom(id));
};

export const clearRoom = () => {
  dispatch(slice.actions.clearRoom());
};

export const getRoomData = (r_id: string): roomDataType => {
  return store.getState().room["rooms"][r_id];
};

export const getRoomListData = (): roomDataType[] => {
  const rooms: any = store.getState().room["rooms"];
  const order: any = store.getState().room["order"];
  return objectToArray(rooms, order);
};

export const getRoomIdListData = (): string[] => {
  return store.getState().room["idList"]
};