import { scenarioDataType } from "../../@types/models/scenario";
import { store, dispatch } from "../store";
import { objectToArray } from "../../utils/dataUtils";
import slice from "../slices/scenario"

export const selectScenario = (scenarioList: scenarioDataType[]) => {
  dispatch(slice.actions.selectScenario(scenarioList));
};

export const updateScenario = (scenario: scenarioDataType) => {
  dispatch(slice.actions.updateScenario(scenario));
};

export const updateScenarioOrder = (order: string[]) => {
  dispatch(slice.actions.updateScenarioOrder(order));
}

export const deleteScenario = (id: string) => {
  dispatch(slice.actions.deleteScenario(id));
};

export const clearScenario = () => {
  dispatch(slice.actions.clearScenario());
};


export const getScenarioData = (s_id: string): scenarioDataType => {
  return store.getState().scenario["scenarios"][s_id];
};

export const getScenarioListData = (): scenarioDataType[] => {
  const scenarios: any = store.getState().scenario["scenarios"];
  const order: any = store.getState().scenario["order"];
  return objectToArray(scenarios, order);
};