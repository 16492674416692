import { respSuccess } from "src/@types/api";
import { userDataType } from "src/@types/models/user";
import dispatchers from "src/redux/dispatchers";
import * as api from "../apis/user";

export const getUser = (id: string) => {
    return new Promise<userDataType>(async (resolve: any, reject) => {
        try {
            const res: respSuccess = await api.getUser(id);
            const user: userDataType = res.data;
            dispatchers.user.selectUser(user);
            resolve(user);
        } catch (error) {
            console.log("getUser error: ", error);
            reject(error);
        }
    });
};
export const exitUser = () => {
    return new Promise<undefined>(async (resolve: any, reject) => {
        try {
            const res: respSuccess = await api.exitUser();
            resolve();
        } catch (error) {
            console.log("exitUser error: ", error);
            reject(error);
        }
    });
};
