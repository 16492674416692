//? ==== hooks ====
import { useLocation, useNavigate } from 'react-router-dom';
import useOffSetTop from '../../hooks/useOffSetTop';
import useResponsive from 'src/hooks/useResponsive';
import useAuth from 'src/hooks/useAuth';

//? ==== components ====
import { AppBar, Toolbar, Container, Box, Link, styled } from '@mui/material';
import Iconify from 'src/components/atoms/Iconify';
import AccountPopover from '../AccountPopover';
import Logo from 'src/components/organisms/Logo';
import MenuDesktop from '../MenuDesktop';
import navConfig from '../MenuConfig';

//? ==== utils ====
import { PATH_CONTENT, PATH_SINGLE } from 'src/routes/paths';

// ----------------------------------------------------------------------

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  display: "flex",
  alignItems: 'center',
  width: '100%',
  background: '#FFF',

  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),

  [theme.breakpoints.up('md')]: {
    minHeight: 72,
  },
}));

const RowCenter = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

// ----------------------------------------------------------------------

export default function MainHeader() {
  const isOffset = useOffSetTop(20);
  const isSmDown = useResponsive("down", "sm");

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isAuthenticated, openAuthPage } = useAuth();

  const isHome = pathname === PATH_CONTENT.root

  const onClickSearch = () => {
    navigate(PATH_SINGLE.root("ContentSearchScreen", undefined));
  }

  return (
    <AppBar
      sx={{
        boxShadow: 0,
        bgcolor: 'transparent'
      }}
    >
      <ToolbarStyle
        disableGutters
        sx={{
          minHeight: 50,
          ...(!isHome && {
            borderBottom: "1px solid #efefef",
          })

        }}
      >
        <Container
          sx={{
            display: 'flex',
            flexDirection: "column",
            justifyContent: 'center'
          }}
          maxWidth="xl"
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              mt: { xs: 0, md: 2 },
              mb: { xs: 0, md: 1 },
            }}
            maxWidth="xl"
          >
            <RowCenter>
              <Logo sx={{ width: isSmDown ? 70 : 120 }} />
            </RowCenter>

            <RowCenter sx={{ mr: 1 }}>
              <MenuDesktop
                isHome={false}
                isOffset={isOffset}
                navConfig={navConfig}
              />

              <Iconify
                icon="charm:search"
                width={{ xs: 22, md: 30 }}
                height={{ xs: 22, md: 30 }}
                sx={{
                  cursor: 'pointer',
                  color: "black",
                  marginRight: { xs: 2, md: 3 },

                  '&:hover': {
                    transform: "scale(1.1)",
                    transitionDuration: "0.2s"
                  },
                }}
                onClick={onClickSearch}
              />
              {
                isAuthenticated ?
                  <AccountPopover />
                  :
                  <Iconify
                    icon="ant-design:user-outlined"
                    width={{ xs: 24, md: 36 }}
                    height={{ xs: 24, md: 36 }}
                    sx={{
                      cursor: 'pointer',
                      color: "black",
                      strokeWidth: '100px',
                      '&:hover': {
                        transform: "scale(1.1)",
                        transitionDuration: "0.2s"
                      },
                    }}
                    onClick={openAuthPage}
                  />
              }
            </RowCenter>
          </Box>
        </Container>
      </ToolbarStyle>
    </AppBar>
  );
}
