export const API_SUCCESS_CODE = {
    API_SUCCESS: 0,
};


/*
    API ERROR CODE
*/
export const API_ERROR_CODE = {
    INTERNAL_SERVER_ERROR: -1,
    DATA_IS_NULL: -2,
    DB_API_ERROR: -3,
    DATA_IS_INVALID: -4,
    DATA_HAVE_NO_REQUIRED: -5,
    DATA_HAVE_EXCLUSIVE: -6,
    DATA_IS_DELETED: -7,
    INVITE_CODE_EXPIRED: -8,

    PERMISSION_INVALID: -21,
    PERMISSION_NOT_MASTER: -22,
    PERMISSION_CANNOT_MATCH_PLACE: -23,
    PLACE_MUST_HAVE_MASTER: -24,

    TOKEN_EXPIRED: -31,
    TOKEN_INVALID: -32,
    CLIENTID_INVALID: -33,
    CLIENT_SECRET_INVALID: -34,

    LINK_NOT_MATCHED_TYPE: -51,
    LIMIT_MAX_PAIRED_NUM: -52,

    DEVICE_CANNOT_COM: -100,
    DEVICE_NOT_CONNECTED: -101,
    DEVICE_ACTION_TIMEOUT: -102,
    DEVICE_CONTROL_ERROR: -103,
    NOT_MINIBIG_DEVICE: -104,

    USER_PROFILE_NOT_EXiST: -120,
    USER_INVALID: -121,
    USER_NAME_EXiST: -122,

    AUTH_NOT_EXiST: -123,
    AUTH_PW_INVALID: -124,
    AUTH_NOT_AUTHENTICATED: -125,
    AUTH_ALREADY_EXiST: -126,
    AUTH_CODE_INVALID: -127,
    AUTH_CODE_EXPIRED: -128,
    AUTH_ERROR_NONE_PROVIDER: -129,
    AUTH_ERROR_KNOWN: -130,
    AUTH_ERROR_LIMIT: -131,
    AUTH_ERROR_DELETED: -132,
}

/*
ACTION TYPE
*/
export const ACTION_TYPE = {
    LOG_OPTYPE_API: 'api',
    LOG_OPTYPE_DASHBOARD: 'dashboard',
    LOG_OPTYPE_MINIBIGAPP: 'minibigapp',
    LOG_OPTYPE_ANDROIDWIDGET: 'androidwidget',
    LOG_OPTYPE_IOSWIDGET: 'iosWidget',
    LOG_OPTYPE_SIRISHORTCUT: 'sirishortcut',
    LOG_OPTYPE_LINK: 'link',
    LOG_OPTYPE_SCENARIO: 'scenario',
    LOG_OPTYPE_EXTENSION_SMARTTHINGS: 'smartthings',
    LOG_OPTYPE_EXTENSION_GOOGLEHOME: 'googlehome',
    LOG_OPTYPE_EXTENSION_NAVERCLOVA: 'naverclova',
}

/*
DEVICE CODE
*/
export const DEVICE_CONFIRM_CODE = {
    DEVICE_SUCCESS: 253,
    DEVICE_NOT_MATCHING: 255,
    DEVICE_BUSY: 254,
    DEVICE_NOT_INITIAL: 252,
    DEVICE_SAME_DIRECTION: 251,
    DEVICE_NOT_MEASURED: 250,
    DEVICE_EXCEED_TIMER_NUM: 238,
    DEVICE_NOT_MACHING_WIFI: 16,
    DEVICE_IR_NOT_LEARNED: 200,
}

/*
   DEVICE ACTION CODE
*/
export const DEVICE_ACTION_CODE = {
    HEAD_DEVICE_REG: 1,
    HEAD_DEVICE_BLE_RESTART: 17,
    HEAD_DEVICE_SET_WIFI_DATA: 18,

    HEAD_DEVICE_UNDEFINED_MSG: 20,
    HEAD_DEVICE_ACTION_BLE: 21,
    HEAD_DEVICE_SCAN_BLE: 22,
    HEAD_DEVICE_OTA: 23,
    HEAD_DEVICE_REPORT_DPS: 24,
    HEAD_DEVICE_RESTART: 25,
    HEAD_DEVICE_RESET_MANU: 26,
    HEAD_DEVICE_CONFIRM_BLE: 27,
    HEAD_HUB_CONFIRM_SERVER_BLE: 28,
    HEAD_DEVICE_UPDATE_DEVICE: 29,
    HEAD_DEVICE_BOOT: 30,
    HEAD_DEVICE_PAIR_DEVICE: 31,
    HEAD_DEVICE_UNPAIR_DEVICE: 32,
    HEAD_DEVICE_UNPAIR_DEVICE_ALL: 33,
    HEAD_DEVICE_CONFIRM_PAIR: 34,
    HEAD_DEVICE_REPORT: 35,
    HEAD_DEVICE_ACTION_BLE_LINK: 36,
    HEAD_DEVICE_GET_PAIR_DEVICE: 37,
    HEAD_DEVICE_SET_WIFI: 38,
    HEAD_DEVICE_CHECK_OTA: 39,
    HEAD_HUB_PAIR_DEVICE_ALL: 40,
    HEAD_HUB_CHECK_PAIR: 41,

    HEAD_DEVICE_PUB_DPS: 42,
    HEAD_DEVICE_READ_DPS: 43,
    HEAD_DEVICE_TIMER_SAVE_NEW: 44,
    HEAD_DEVICE_TIMER_READ_NEW: 45,
    HEAD_DEVICE_TIMER_DELETE_NEW: 46,
    HEAD_DEVICE_MANU_RESET_NEW: 47,
    HEAD_DEVICE_CENTRAL_SAVE: 48,
    HEAD_DEVICE_CENTRAL_READ: 49,
    HEAD_DEVICE_CENTRAL_DELETE: 50,

    HEAD_DEVICE_ZB: 59,
    HEAD_DEVICE_ACTION_SCENARIO: 61,
    HEAD_DEVICE_DEBUG: 62,

    HEAD_DEVICE_ACTION_IR: 80,
    HEAD_DEVICE_ACTION_IR_LEARNING: 81,
};

export const SCENARIO_IMAGE_LIST = [
    {
      name: "basic",
    },
  
    {
      name: "in_a",
    },
    {
      name: "out_a",
    },
    {
      name: "location",
    },
  
    {
      name: "star",
    },
    {
      name: "star_red",
    },
    {
      name: "sleep",
    },
    {
      name: "cloud",
    },
    {
      name: "partly_cloud",
    },
    {
      name: "rain",
    },
    {
      name: "drop",
    },
    {
      name: "snow",
    },
    {
      name: "sun",
    },
    {
      name: "flower",
    },
  
    {
      name: "temperature",
    },
    {
      name: "humidity",
    },
    {
      name: "dot-grid",
    },
  
    {
      name: "movie",
    },
    {
      name: "trip",
    },
    {
      name: "rest",
    },
  ]

export const LOG_ACTION_TYPE = {
    KNOWN: 1,
    API: 2,
    APP_LEGACY: 3,
    WIDGET_ANDROID_LEGACY: 4,
    WIDGET_IOS_LEGACY: 5,
    WIDGET_SIRI_LEGACY: 6,
    SCENARIO_LEGACY: 7,
    APP_TEST: 8,
    
    APP_DEVICE_ANDROID: 10,
    APP_DEVICE_IOS: 11,
    APP_SCENARIO_ANDROID: 12,
    APP_SCENARIO_IOS: 13,

    WIDGET_DEVICE_ANDROID: 31,
    WIDGET_SCENARIO_ANDROID: 32,
    WIDGET_SCENARIO_1_ANDROID: 33,
    WIDGET_DEVICE_IOS: 34,
    WIDGET_SCENARIO_IOS: 35,
    SIRI_SCENARIO_IOS: 36,
    SIRI_DEVICE_IOS: 37,

    LINK_DEVICE: 50,
    LINK_SCENARIO: 51,

    EXT_ST: 60,
    EXT_CV: 61,
    EXT_GH: 62,

    WATCH_SCENARIO_ANDROID: 90,
    WATCH_SCENARIO_IOS: 91,

    WEB_DEVICE: 100,
    WEB_SCENARIO: 101,
}

export const LOG_COM_TYPE = {
    //'ble' | 'mqtt' | 'api' | 'tuya';
    KNOWN: 0,
    API: 1,
    BLE: 2,
    MQTT: 3,
    TUYA_MQTT: 11,
}