//? ==== hooks ====
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';
import useResponsive from 'src/hooks/useResponsive';
import useAuth from '../hooks/useAuth';
import useIsMounted from '../hooks/useIsMounted';

//? ==== components ====
import { alpha, Box, Divider, Typography, Stack, MenuItem, Drawer, useTheme } from '@mui/material';
import MyAvatar from 'src/components/organisms/MyAvatar';
import MenuPopover from 'src/components/molecules/MenuPopover';
import Iconify from 'src/components/atoms/Iconify';
import { IconButtonAnimate } from 'src/components/animate';

//? ==== utils ====
import { PATH_AUTH, PATH_CONTENT, PATH_DASHBOARD, PATH_SINGLE, PATH_STORE } from '../routes/paths';
import useEnvironment from 'src/hooks/useEnvironment';

type MenuOptionType = {
  label: string,
  linkTo: string,
  authority?: string;
  hardware?: string;
}

// ----------------------------------------------------------------------

const MENU_OPTIONS: MenuOptionType[] = [
  {
    label: '홈',
    linkTo: PATH_CONTENT.root,
    // hardware: "desktop"
  },
  // {
  //   label: '콘텐츠',
  //   linkTo: PATH_CONTENT.root,
  //   hardware: "mobile"
  // },
  // {
  //   label: '대시보드',
  //   linkTo: PATH_DASHBOARD.root,
  //   hardware: "mobile"
  // },
  // {
  //   label: '스토어',
  //   linkTo: PATH_STORE.root,
  //   hardware: "mobile"
  // },
  {
    label: '콘텐츠 제작',
    linkTo: PATH_CONTENT.new,
    authority: 'content'
  },
  {
    label: '나의 콘텐츠',
    linkTo: PATH_SINGLE.root("MyContentScreen"),
    authority: 'content'
  },
];

// ----------------------------------------------------------------------


export default function AccountPopover() {
  const { colors } = useTheme();
  const navigate = useNavigate();
  const env = useEnvironment();
  const isSmUp = useResponsive('up', 'sm');
  const isMounted = useIsMounted();

  const { user, logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = async () => {
    try {
      await logout();
      if (isMounted()) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  const handleExit = () => {
    navigate(PATH_AUTH.exit);
  };

  const onClickItem = (item: MenuOptionType) => {
    handleClose();
    if (item.linkTo.startsWith('https://')) {
      window.open(item.linkTo, '_blank');
      return;
    }
    navigate(item.linkTo);
  }

  const renderMenuItem = (item: MenuOptionType) => {
    const { authority, hardware, label, linkTo } = item;
    const isVisible = (!authority && !hardware) || (authority && user?.role?.includes(authority)) || (hardware && hardware == env.hardware);

    if (isVisible) {
      return (
        <MenuItem
          key={label}
          onClick={() => onClickItem(item)}
        >
          {label}
        </MenuItem>
      )
    }
    return <div key={label} />
  }

  return (
    <React.Fragment>
      <IconButtonAnimate
        sx={{
          p: 0,
          width: 35,
          height: 35,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
        onClick={handleOpen}
      >
        <MyAvatar
          sx={{
            width: { xs: 30, md: 40 },
            height: { xs: 30, md: 40 },
          }}
        />
      </IconButtonAnimate>

      {
        isSmUp ?
          <MenuPopover
            open={Boolean(open)}
            anchorEl={open}
            onClose={handleClose}
            sx={{
              p: 0,
              mt: 1.5,
              ml: 0.75,
              '& .MuiMenuItem-root': {
                typography: 'body2',
                borderRadius: 0.75,
              },
            }}
          >
            <Box sx={{ my: 1.5, px: 2.5 }}>
              <Typography variant="subtitle2" noWrap>
                {user?.name}
              </Typography>
              <Typography variant="body2" sx={{ color: colors.subtitle }} noWrap>
                {user?.email}
              </Typography>
            </Box>

            <Divider sx={{ borderStyle: 'dashed' }} />

            <Stack sx={{ p: 1 }}>
              {
                MENU_OPTIONS.map((item) => (
                  renderMenuItem(item)
                ))
              }
            </Stack>

            <Divider sx={{ borderStyle: 'dashed' }} />

            <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
              로그아웃
            </MenuItem>
            <MenuItem onClick={handleExit} sx={{ m: 1 }}>
              회원탈퇴
            </MenuItem>
          </MenuPopover>
          :
          <Drawer
            anchor='right'
            open={Boolean(open)}
            onClose={handleClose}
            PaperProps={{
              sx: {
                borderTopLeftRadius: 15,
                borderBottomLeftRadius: 15,
                width: '50%',
                p: 1
              }
            }}
          >
            <Box sx={{ display: "flex", justifyContent: 'end', mb: 2, pr: 1, pt: 0.8 }}>
              <Iconify
                icon="lucide:x"
                width={30}
                height={30}
                onClick={handleClose}
                sx={{
                  cursor: 'pointer',
                  color: "#222",
                }}
              />
            </Box>
            {
              MENU_OPTIONS.map((item) => (
                renderMenuItem(item)
              ))
            }
            <MenuItem onClick={handleLogout}>
              로그아웃
            </MenuItem>
            <MenuItem onClick={handleExit} sx={{ m: 1 }}>
              회원탈퇴
            </MenuItem>
          </Drawer>
      }
    </React.Fragment>
  );
}
