import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
// components
import Logo from 'src/components/organisms/Logo';
import useResponsive from 'src/hooks/useResponsive';

// ----------------------------------------------------------------------

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: '100%',
  position: 'absolute',
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(5, 5, 0)
  }
}));

// ----------------------------------------------------------------------

export default function LogoOnlyLayout() {
  const isMdUp = useResponsive('up', 'md');

  return (
    <>
      <HeaderStyle>
        <Logo
          sx={{ width: isMdUp ? 120 : 75 }}
        />
      </HeaderStyle>
      <Outlet />
    </>
  );
}
