import { createSlice } from "@reduxjs/toolkit";
import { roomDataType, roomStateType } from "src/@types/models/room";
import { createIdList, } from "src/utils/dataUtils";
import { dispatch } from "../store";

const initialState: roomStateType = {
    loaded: false,
    idList: [],
    rooms: {},
    order: [],
};

const slice = createSlice({
    name: 'room',
    initialState,
    reducers: {
        selectPlace(state: roomStateType, action: any) {
            const newDatas: any = {};
            const roomList = action.payload;

            for (const room of roomList) {
                newDatas[room.id] = room;
            }

            const order = state.order;
            const idList = createIdList(newDatas, order);
            state.loaded = true;
            state.rooms = newDatas;
            state.idList = idList
        },

        updateRoom(state: roomStateType, action: any) {
            const room = action.payload;
            const newDatas = { ...state.rooms };
            newDatas[room.id] = room;
            state.rooms = newDatas

            const idList = [...state.idList];
            if (!idList.includes(room.id)) {
                idList.push(room.id);
            }
            state.idList = idList;
        },

        updateRoomOrder(state: roomStateType, action: any) {
            const order = action.payload;
            const idList = createIdList(state.rooms, order);

            state.order = order
            state.idList = idList
        },

        deleteRoom(state: roomStateType, action: any) {
            const id = action.payload;
            const newDatas = { ...state.rooms };
            delete newDatas[id];
            state.rooms = newDatas;
            const idList = [...state.idList];
            const newIdList = idList.filter((e) => {
                if (e === id) {
                    return false;
                } else {
                    return true;
                }
            });
            state.idList = newIdList;
        },

        clearRoom(state: roomStateType) {
            state = {
                loaded: false,
                rooms: {},
                idList: [],
                order: [],
            }
        },
    },
});

// Reducer
export default slice;

