import { createSlice } from "@reduxjs/toolkit";

type systemStateType = {
    loaded: boolean;
}

const initialState: systemStateType = {
    loaded: false,
};

const slice = createSlice({
    name: 'system',
    initialState,
    reducers: {
        updateLoaded(state: systemStateType, action: any) {
            state.loaded = action.payload;
        },
        clearUser(state: systemStateType) {
            state = {
                loaded: false
            }
        },
    },
});

// Reducer
export default slice;