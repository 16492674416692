import { deviceDataType } from "../../@types/models/device";
import { objectToArray } from "../../utils/dataUtils";
import { store, dispatch } from "../store";
import slice from "../slices/device";

export const selectDevice = (deviceList: deviceDataType[]) => {
  dispatch(slice.actions.selectDevice(deviceList));
};

export const updateDevice = (device: deviceDataType) => {
  dispatch(slice.actions.updateDevice(device));
};

export const updateDeviceOrder = (order: string[]) => {
  dispatch(slice.actions.updateDeviceOrder(order));
}

export const deleteDevice = (id: string) => {
  dispatch(slice.actions.deleteDevice(id));
};

export const clearDevice = () => {
  dispatch(slice.actions.clearDevice());
};

export const getDeviceData = (d_id: string): deviceDataType => {
  return store.getState().device["devices"][d_id];
};

export const getDeviceDataByMac = (mac: string): deviceDataType | undefined => {
  if (mac == undefined) {
    return undefined;
  }
  const devices: any = store.getState().device["devices"];
  for (const id in devices) {
    if (devices[id].mac != undefined && devices[id].mac.toLowerCase() == mac.toLowerCase()) {
      return devices[id];
    }
  }
  return undefined;
};

export const getDeviceListData = (): deviceDataType[] => {
  const devices: any = store.getState().device["devices"];
  const order: any = store.getState().device["order"];

  return objectToArray(devices, order);
};

export const getPaiedDeviceListData = (h_id: string): deviceDataType[] => {
  const deviceList = getDeviceListData();
  return deviceList.filter((e) => {
    if (e.h_id === h_id) {
      return true;
    }
    return false;
  })
};