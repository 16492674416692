import { useLocation } from "react-router";
import { saveAuthAfter } from "src/utils/storage";

// ----------------------------------------------------------------------
export type RootType = 'content' | 'dashboard' | 'other';

export default function useRoot() {
    const { pathname } = useLocation();
    var root: RootType = 'dashboard';

    if (pathname.includes('/content') ||
        pathname.includes('/contents') ||
        pathname.includes('/search') ||
        pathname.includes('/ContentDetailScreen') ||
        pathname.includes('/ContentSearchResultScreen') ||
        pathname.includes('/ContentSearchScreen') ||
        pathname.includes('/MyContentScreen')
    ) {
        root = 'content';
    }
    else if (pathname.includes('/BeInvitedScreen')
    ) {
        root = 'other';
    }

    saveAuthAfter(root);

    return root
}
