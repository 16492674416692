import useSettings from "./useSettings";
import queryString from "query-string";
import useRoot from "./useRoot";

export default function useIsLight() {
    const root = useRoot();
    const { themeMode } = useSettings();
    if (root == 'content') {
        return { isLight: true }
    }
    const query = queryString.parse(window.location.search) || {};
    const { dark } = query;
    if (dark != undefined) {
        return { isLight: dark != 'Y' };
    }
    return { isLight: themeMode === 'light' };
}
