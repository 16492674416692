import slice from "../slices/place"
import { placeDataType } from "../../@types/models/place";
import { store, dispatch } from "../store";
import { objectToArray } from "src/utils/dataUtils";

export const selectPlace = (placeList: placeDataType[]) => {
  dispatch(slice.actions.selectPlace(placeList));
}

export const selectCurrentPlace = (id: string) => {
  dispatch(slice.actions.selectCurrentPlace(id));
}

export const updatePlace = (place: placeDataType) => {
  dispatch(slice.actions.updatePlace(place));
}

export const updatePlaceOrder = (order: string[]) => {
  dispatch(slice.actions.updatePlaceOrder(order));
}

export const deletePlace = (id: string) => {
  dispatch(slice.actions.deletePlace(id));
}

export const clearPlace = () => {
  dispatch(slice.actions.clearPlace());
}

export const getCurrentPlaceData = (): placeDataType | undefined => {
  return store.getState().place["currentPlace"];
};

export const getPlaceData = (p_id: string): placeDataType => {
  return store.getState().place["places"][p_id];
};

export const getPlaceListData = (): placeDataType[] => {
  const list: placeDataType[] = [];
  const places: any = store.getState().place["places"];
  const order: string[] = store.getState().place["order"];

  for (const place of objectToArray(places, order)) {
    list.push(place);
  }

  return list;
};