import axios from '../../../utils/axios';
import { respSuccess, respFail } from "src/@types/api";
import { getLanguageTag } from "../../../utils/language";
import { lifeDataCreateType, lifeDataUpdateType } from "../../../@types/models/life";

export const queryLifeThemes = (p_id: string) => {
    return axios.get<respSuccess | respFail>(
        "/life/theme/query", { params: { p_id, locale: getLanguageTag() } }
    );
};

export const getLifeThemeDetail = (theme: string) => {
    return axios.get<respSuccess | respFail>(
        "/life/theme/detail", { params: { theme, locale: getLanguageTag() } }
    );
};

export const getLife = (life_id: string) => {
    const params = { life_id };
    return axios.get<respSuccess | respFail>("/life/read", { params });
};

export const queryLife = (p_id: string) => {
    const params = { p_id };
    return axios.get<respSuccess | respFail>("/life/query", { params });
};

export const createLife = (item: lifeDataCreateType) => {
    const {
        title,
        theme,
        p_id
    } = item;

    return axios.post<respSuccess | respFail>("/life/create", { p_id, item: { name: title, theme } });
};

export const updateLife = (life_id: string, item: lifeDataUpdateType) => {
    const {
        info,
        p_id
    } = item;

    return axios.post<respSuccess | respFail>("/life/update", { item: { info }, life_id, p_id });
};

export const deleteLife = (life_id: string) => {
    const params = { life_id };

    return axios.delete<respSuccess | respFail>("/life/delete", { params });
};