import { respFail, respSuccess } from "src/@types/api";
import { placeDataTypeForCreate } from "src/@types/models/place";
import axios from "../../utils/axios";

export const getPlace = (id: string) => {
  const params = { id };
  return axios.get<respSuccess | respFail>("/place/get", { params });
};

export const getPlaceDetail = (id: string) => {
  const params = { id };
  return axios.get<respSuccess | respFail>("/place/getDetail", { params });
};

export const queryPlace = (u_id: string) => {
  const params = { u_id };
  return axios.get<respSuccess | respFail>("/place/query/userId", { params });
};

export const createPlace = (place: placeDataTypeForCreate) => {
  return axios.post<respSuccess | respFail>("/place/create", { item: place });
};

export const updatePlace = (id: string, place: any) => {
  return axios.post<respSuccess | respFail>("/place/update", { id, item: place });
};

export const deletePlace = (id: string) => {
  const params = { id };
  return axios.delete<respSuccess | respFail>("/place/delete", { params })
};
