import { colorType } from "src/@types/palette";

const colors = {
    primary: "#3C6CF7",
    delete: "#FF3C1D",
    white: "#FFFFFF",
    black: "#383838",
    textLight: "#E5E5E7",
    textDark: "#1C1C1E"
}

export const colorsLight: colorType = {
    primary: colors.primary,
    lightPrimary: "#F2F5FE",
    background: "#FBFBFB",
    card: colors.white,
    cardDark: "#EFEFEF",
    text: colors.textDark,
    textReverse: colors.textLight,
    chipText: colors.primary,
    subtitle: "#A4A2AA",
    border: "#EDEDED",
    icon: "#C7C7CC",
    inputBackground: "#EFEFEF",
    deviceIcon: "#717171",
    deviceIconDisabled: "#DBDBDB",
    deviceIconBackground: "#F8F8F8",
    IrButton: "#EEEEEE",
    IrButtonDisabled: "#F7F7F7",
    IrButtonIcon: "#717171",
    IrButtonIconDisabled: "#DDDDDD",
    powerButton: "#E03B2E",
    delete: colors.delete,
    white: colors.white,
    black: colors.black,
}

export const colorsDark: colorType = {
    primary: colors.primary,
    lightPrimary: "#0C234D",
    background: "#010101",
    card: "#232323",
    cardDark: "#1D1D1D",
    text: colors.textLight,
    textReverse: colors.textDark,
    chipText: "#3572ED",
    subtitle: "#5D5D5D",
    border: "#171717",
    icon: "#8E8E8E",
    inputBackground: "#303030",
    deviceIcon: "#C6C6C6",
    deviceIconDisabled: "#404040",
    deviceIconBackground: "#191919",
    IrButton: "#303030",
    IrButtonDisabled: "#191919",
    IrButtonIcon: colors.textLight,
    IrButtonIconDisabled: "#303030",
    powerButton: "#E03B2E",
    delete: colors.delete,
    white: colors.white,
    black: colors.black,
}