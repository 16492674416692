import { linkDataType } from "../../@types/models/link";
import { store, dispatch } from "../store";
import { objectToArray } from "../../utils/dataUtils";
import slice from "../slices/link"

export const selectLink = (linkList: linkDataType[]) => {
  dispatch(slice.actions.selectLink(linkList));
};

export const updateLink = (link: linkDataType) => {
  dispatch(slice.actions.updateLink(link));
};

export const updateLinkOrder = (order: string[]) => {
  dispatch(slice.actions.updateLinkOrder(order));
};

export const deleteLink = (id: string) => {
  dispatch(slice.actions.deleteLink(id));
};

export const clearLink = () => {
  dispatch(slice.actions.clearLink());
};


export const getLinkData = (l_id: string): linkDataType => {
  return store.getState().link["links"][l_id];
};

export const getLinkListData = (): linkDataType[] => {
  const links: any = store.getState().link["links"];
  const order: any = store.getState().link["order"];
  return objectToArray(links, order);
};