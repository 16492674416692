//? ==== hooks ====
import { ReactNode, createContext } from 'react';
import useLocalStorage from '../hooks/useLocalStorage';

//? ==== utils ====
import { defaultPreset } from '../utils/getColorPresets';

export type ThemeMode = 'light' | 'dark';
export type ThemeDirection = 'rtl' | 'ltr';
export type ThemeColorPresets = 'default' | 'purple' | 'cyan' | 'blue' | 'orange' | 'red';
export type ThemeLayout = 'vertical' | 'horizontal';
export type ThemeStretch = boolean;

type ColorVariants = {
    name: string;
    lighter: string;
    light: string;
    main: string;
    dark: string;
    darker: string;
    contrastText: string;
};

export type SettingsValueProps = {
    themeMode: ThemeMode;
    themeDirection: ThemeDirection;
    themeColorPresets: ThemeColorPresets;
    themeStretch: ThemeStretch;
    themeLayout: ThemeLayout;
};

export type SettingsContextProps = {
    open: boolean;
    onToggleOpen: VoidFunction;
    onChangeOpen: (value: boolean) => void;
    themeMode: ThemeMode;
    themeDirection: ThemeDirection;
    themeColorPresets: ThemeColorPresets;
    themeLayout: ThemeLayout;
    themeStretch: boolean;
    setColor: ColorVariants;
    colorOption: {
        name: string;
        value: string;
    }[];
    onToggleMode: VoidFunction;
    onToggleStretch: VoidFunction;
    onResetSetting: VoidFunction;
    onChangeMode: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeDirection: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeColor: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeLayout: (event: React.ChangeEvent<HTMLInputElement>) => void;
};


export const defaultSettings: SettingsValueProps = {
    themeMode: 'light',
    themeDirection: 'ltr',
    themeColorPresets: 'default',
    themeLayout: 'horizontal',
    themeStretch: false,
};

// ----------------------------------------------------------------------

const initialState: SettingsContextProps = {
    open: false,
    onToggleOpen: () => { },
    onChangeOpen: () => { },
    ...defaultSettings,
    onChangeMode: () => { },
    onToggleMode: () => { },
    onChangeDirection: () => { },
    onChangeColor: () => { },
    onToggleStretch: () => { },
    onChangeLayout: () => { },
    onResetSetting: () => { },
    setColor: defaultPreset,
    colorOption: [],
};

const SettingsContext = createContext(initialState);

type SettingsProviderProps = {
    children: ReactNode;
};

function SettingsProvider({ children }: SettingsProviderProps) {
    const [settings, setSettings] = useLocalStorage('settings', {
        themeMode: initialState.themeMode,
        themeDirection: initialState.themeDirection,
        themeColorPresets: initialState.themeColorPresets,
        themeStretch: initialState.themeStretch,
        themeLayout: initialState.themeLayout,
    });

    const onToggleOpen = () => {
        setSettings({
            ...settings,
            open: !settings.open,
        });
    };

    const onChangeOpen = (value: boolean) => {
        setSettings({
            ...settings,
            open: value,
        });
    };

    const onChangeMode = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSettings({
            ...settings,
            themeMode: (event.target as HTMLInputElement).value as ThemeMode,
        });
    };

    const onToggleMode = () => {
        setSettings({
            ...settings,
            themeMode: settings.themeMode === 'light' ? 'dark' : 'light',
        });
    };

    const onChangeDirection = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSettings({
            ...settings,
            themeDirection: (event.target as HTMLInputElement).value as ThemeDirection,
        });
    };

    const onChangeColor = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSettings({
            ...settings,
            themeColorPresets: (event.target as HTMLInputElement).value as ThemeColorPresets,
        });
    };

    const onChangeLayout = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSettings({
            ...settings,
            themeLayout: (event.target as HTMLInputElement).value as ThemeLayout,
        });
    };

    const onToggleStretch = () => {
        setSettings({
            ...settings,
            themeStretch: !settings.themeStretch,
        });
    };

    const onResetSetting = () => {
        setSettings({
            themeMode: initialState.themeMode,
            themeLayout: initialState.themeLayout,
            themeStretch: initialState.themeStretch,
            themeDirection: initialState.themeDirection,
            themeColorPresets: initialState.themeColorPresets,
        });
    };

    return (
        <SettingsContext.Provider
            value={{
                ...settings,
                onToggleOpen,
                onChangeOpen,

                // Mode
                onChangeMode,
                onToggleMode,
                // Direction
                onChangeDirection,
                // Color
                // onChangeColor,
                // setColor: getColorPresets(settings.themeColorPresets),
                // colorOption: colorPresets.map((color) => ({
                //   name: color.name,
                //   value: color.main,
                // })),
                // Stretch
                onToggleStretch,
                // Navbar Horizontal
                onChangeLayout,
                // Reset Setting
                onResetSetting,
            }}
        >
            {children}
        </SettingsContext.Provider>
    );
}

export { SettingsProvider, SettingsContext };
