import * as user from './user';
import * as device from './device';
import * as scenario from './scenario';
import * as place from './place';
import * as room from './room';
import * as link from './link';
import * as life from './life';
import * as system from './system';

export default {
  user,
  device,
  scenario,
  place,
  room,
  link,
  life,
  system
};
