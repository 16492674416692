//? ==== hooks ====
import { Outlet } from 'react-router-dom';

//? ==== components ====
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import MainFooter from './MainFooter';
import MainHeader from './MainHeader';
import MoveTopBtn from 'src/components/atoms/MoveTopBtn';
import { AppHeader } from './AppHeader';

//? ==== utils ====
import queryString from "query-string";

// ----------------------------------------------------------------------

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})
  (({ theme }) => ({
    flexGrow: 1,
    paddingTop: 40,
    paddingBottom: 16,
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64,
      paddingBottom: 56,
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: 116,
    },
  }));

// ----------------------------------------------------------------------

export default function MainLayout() {
  const query = queryString.parse(window.location.search);
  const onApp = query.onApp === 'Y';

  return (
    <Box
      sx={
        onApp ?
          {
            minHeight: { lg: 1 },
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
              display: 'none'
            },
            height: '100%'
          } :
          {
            minHeight: { lg: 1 },
          }}
    >
      {
        !onApp ?
          <MainHeader />
          :
          <AppHeader />
      }

      <MainStyle sx={{ pb: onApp ? 5 : undefined }}>
        <Outlet />
        <MoveTopBtn />
      </MainStyle>

      {
        !onApp && <MainFooter />
      }

    </Box >
  );
}
