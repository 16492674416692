import { userDataType } from "../../@types/models/user";
import { store, dispatch } from "../store";
import slice from "../slices/user"

export const selectUser = (userData: userDataType) => {
    dispatch(slice.actions.selectUser(userData));
};

export const updateUser = (userData: userDataType) => {
    dispatch(slice.actions.updateUser(userData));
};

export const clearUser = () => {
    dispatch(slice.actions.clearUser());
};

export const getUserData = (): userDataType => {
    return store.getState().user.userData;
};