import { createContext, ReactNode, useEffect, useReducer } from 'react';
// utils
import axios, { refreshTokenData } from '../utils/axios_auth';
import { isValidToken, setSession, getSession, clearSession } from '../utils/jwt';
// @types
import { ActionMap, AuthState, AuthUser, Auth2ContextType } from '../@types/models/auth';
import { AUTH_CLIENT_ID, AUTH_CLIENT_SECRET, AUTH_DOMAIN, AUTH_REDIRECT_URI } from '../config';
import { exitUser, getUser } from 'src/actions/logics/user';
import dispatchers from 'src/redux/dispatchers';
import { bootingLogic } from 'src/actions/logics/boot';
import useEnvironment from 'src/hooks/useEnvironment';
import { userDataType } from 'src/@types/models/user';
import { base64EncodeString, getRandomNumber } from 'src/utils/dataUtils';
import { getAuthLastState, saveAuthLastState } from 'src/utils/storage';
// ----------------------------------------------------------------------

enum Types {
    Initial = 'INITIALIZE',
    Login = 'LOGIN',
    Logout = 'LOGOUT',
}

type JWTAuthPayload = {
    [Types.Initial]: {
        isAuthenticated: boolean;
        user: AuthUser;
    };
    [Types.Login]: {
        user: AuthUser;
    };
    [Types.Logout]: undefined;
};

export type JWTActions = ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
    isAuthenticated: false,
    isInitialized: false,
    user: null,
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
    switch (action.type) {
        case 'INITIALIZE':
            return {
                isAuthenticated: action.payload.isAuthenticated,
                isInitialized: true,
                user: action.payload.user,
            };
        case 'LOGIN':
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload.user,
            };
        case 'LOGOUT':
            return {
                ...state,
                isAuthenticated: false,
                user: null,
            };
        default:
            return state;
    }
};

const AuthContext = createContext<Auth2ContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
    children: ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
    const { source } = useEnvironment();
    const [state, dispatch] = useReducer(JWTReducer, initialState);

    useEffect(() => {
        if (source == "browser") {
            initialize();
        }
    }, []);

    const initialize = async (userData?: userDataType) => {
        try {
            var {
                accessToken,
                refreshToken
            } = getSession();
            if (accessToken && refreshToken) {
                setSession(accessToken, refreshToken);
                if (!isValidToken(accessToken)) {
                    const res = await refreshTokenData(refreshToken);
                    accessToken = res.accessToken;
                    refreshToken = res.refreshToken;
                }

                var user;
                if (source == "browser") {
                    const response: any = await axios.post('/oauth2/getUserInfo', {
                        access_token: accessToken,
                    });
                    user = await getUser(response.id);
                    bootingLogic(user.id);
                }
                else {
                    user = userData;
                }
                dispatch({
                    type: Types.Initial,
                    payload: {
                        isAuthenticated: true,
                        user: user!,
                    },
                });
            } else {
                dispatch({
                    type: Types.Initial,
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                });
            }
        } catch (err) {
            console.error(err);
            dispatch({
                type: Types.Initial,
                payload: {
                    isAuthenticated: false,
                    user: null,
                },
            });
        }
    };

    const login = (code: string, state: string) =>
        new Promise<void>(async (resolve, reject) => {
            try {
                if (state != getAuthLastState()) {
                    reject(undefined);
                    return;
                }
                const payload = {
                    authorization: "Basic " + base64EncodeString(AUTH_CLIENT_ID + ":" + AUTH_CLIENT_SECRET),
                    client_id: AUTH_CLIENT_ID,
                    client_secret: AUTH_CLIENT_SECRET,
                    code,
                    redirect_uri: AUTH_REDIRECT_URI,
                    grant_type: 'authorization_code',
                };

                var response: any = await axios.post('/oauth2/token', payload);
                const {
                    access_token, refresh_token
                } = response;
                response = await axios.post('/oauth2/getUserInfo', {
                    access_token,
                });
                setSession(access_token, refresh_token);
                const user = await getUser(response.id);
                bootingLogic(response.id);
                dispatch({
                    type: Types.Login,
                    payload: {
                        user,
                    },
                });
                resolve();
            } catch (err) {
                reject(err);
            }
        })

    const openAuthPage = () => {
        const state_value = `${getRandomNumber()}`;
        const params = {
            redirect_uri: AUTH_REDIRECT_URI,
            response_type: 'code',
            client_id: AUTH_CLIENT_ID,
            state: state_value,
            scope: 'minibig.all',
        }
        saveAuthLastState(state_value);
        const searchParams = new URLSearchParams(params);
        const url = AUTH_DOMAIN + '?' + searchParams.toString();
        window.location.href = url;
    }

    const logout = async () => {
        clearSession();
        try {
            dispatchers.user.clearUser();
            dispatchers.device.clearDevice();
            dispatchers.place.clearPlace();
            dispatchers.room.clearRoom();
            dispatchers.scenario.clearScenario();
            dispatchers.link.clearLink();
        } catch (error) {

        }
        dispatch({ type: Types.Logout });
    };

    const exit = async () => {
        try {
            await exitUser();
            await logout();
        } catch (error) {

        }
        dispatch({ type: Types.Logout });
    };

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'auth2',
                login,
                logout,
                initialize,
                openAuthPage,
                exit,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}

export { AuthContext, AuthProvider };
