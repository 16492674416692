//? ==== hooks ====
import useSettings from 'src/hooks/useSettings';
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';

//? ==== components ====
import { Box, Stack, AppBar, Toolbar, styled, useTheme } from '@mui/material';
import Logo from 'src/components/organisms/Logo';
import Iconify from 'src/components/atoms/Iconify';
import { IconButtonAnimate } from 'src/components/animate';
import AccountPopover from '../../AccountPopover';
import MenuDesktop from 'src/layouts/MenuDesktop';
import navConfig from 'src/layouts/MenuConfig';
import FillSvgIcon from 'src/components/atoms/FillSvgIcon';

//? ==== utils ====
import { HEADER, NAVBAR } from '../../../config';
import cssStyles from '../../../utils/cssStyles';

type RootStyleProps = {
    isCollapse: boolean;
    isOffset: boolean;
    verticalLayout: boolean;
};

const RootStyle = styled(AppBar, {
    shouldForwardProp: (prop) =>
        prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout',
})<RootStyleProps>(({ isCollapse, isOffset, verticalLayout, theme }) => ({
    ...cssStyles(theme).bgBlur(),
    boxShadow: 'none',
    height: HEADER.MOBILE_HEIGHT,
    zIndex: theme.zIndex.appBar + 1,
    transition: theme.transitions.create(['width', 'height'], {
        duration: theme.transitions.duration.shorter,
    }),
    [theme.breakpoints.up('lg')]: {
        height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
        width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
        ...(isCollapse && {
            width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
        }),
        ...(isOffset && {
            height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
        }),
        ...(verticalLayout && {
            width: '100%',
            height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
            backgroundColor: theme.palette.background.default,
        }),
    },
}));

// ----------------------------------------------------------------------

type Props = {
    isCollapse?: boolean;
    verticalLayout?: boolean;

    onOpenSidebar: VoidFunction;
    onOpenDrawer: React.MouseEventHandler;
};

export default function DashboardHeader({
    onOpenDrawer,
    onOpenSidebar,
    isCollapse = false,
    verticalLayout = false,
}: Props) {
    const { colors } = useTheme();

    const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;
    const isDesktop = useResponsive('up', 'lg');
    const { onToggleOpen } = useSettings();

    return (
        <RootStyle isCollapse={isCollapse} isOffset={isOffset} verticalLayout={verticalLayout}>
            <Toolbar
                sx={{
                    minHeight: '100% !important',
                    px: { lg: 5 },
                }}
            >
                {isDesktop && verticalLayout && <Logo isSymbol={true} sx={{ mr: 2.5 }} />}

                {
                    !isDesktop && (
                        <IconButtonAnimate onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
                            <Iconify icon="eva:menu-2-fill" />
                        </IconButtonAnimate>
                    )
                }

                <Box sx={{ flexGrow: 1 }} />

                <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
                    <MenuDesktop isOffset={isOffset} isHome={false} navConfig={navConfig} />
                    <Stack direction={"row"} alignItems={"center"}>
                        <IconButtonAnimate onClick={onOpenDrawer}>
                            <FillSvgIcon src={`/icons/ic_order.svg`} iconSize={30} fill={colors.icon} />
                        </IconButtonAnimate>
                        <IconButtonAnimate onClick={onToggleOpen}>
                            <Iconify icon="eva:options-2-fill" width={23} height={23} color={colors.icon} />
                        </IconButtonAnimate>
                    </Stack>
                    {/* <LanguagePopover /> */}
                    {/* <NotificationsPopover /> */}
                    <AccountPopover />
                </Stack>
            </Toolbar>
        </RootStyle>
    );
}
