//? ==== routes ====
import { PATH_CONTENT } from './routes/paths';
import { getRandomNumber } from './utils/dataUtils';

//? ==== google ====
export const googleAnalyticsConfig = "G-GT932X3YTV";

//? ======== API ========
export const PATH_AFTER_LOGIN = PATH_CONTENT.root;

const baseUrlDev = `https://dev.minibig-api.com/`;
const baseUrl = `https://prod.minibig-api.com/`
const baseAuthUrlDev = `https://auth-dev.minibig-api.com/`;
const baseAuthUrl = `https://auth.minibig-api.com/`;

const domainAuthDev = `https://auth-dev.minibig.life/`;
const domainAuth = `https://auth.minibig.life/`;

const isProduction = ((window.location.origin).includes("minibig.life") && !(window.location.origin).includes("dev.minibig.life")) ? true : false;

export const HOST_API = isProduction ? baseUrl : baseUrlDev;
export const HOST_API_AUTH = isProduction ? baseAuthUrl : baseAuthUrlDev;
export const AUTH_DOMAIN = isProduction ? domainAuth : domainAuthDev;
export const AUTH_CLIENT_ID = '5pVdVGQL6q4oMNdwbTzu';
export const AUTH_CLIENT_SECRET = 'CNjNrWLeVriirJwph16tR6enan2FBKbzBFNBgLSy';
export const AUTH_REDIRECT_URI = window.location.origin + '/auth/callback';

//? ======== LAYOUT ========
export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 92,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};