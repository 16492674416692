import { ProductDataType } from "src/@types/models/product"
import { StoreProductType } from "src/@types/models/store"

export const sendToPlaitAppContentDetail = (c_id: number) => {
    if (window?.ReactNativeWebView) {
        const params = {
            type: 'moveToContentDetail',
            c_id,
        }
        window.ReactNativeWebView.postMessage(JSON.stringify(params))
    }
}

export const sendToPlaitEditorDetail = (editor: string) => {
    if (window?.ReactNativeWebView) {
        const params = {
            type: 'moveToEditorDetail',
            editor,
        }
        window.ReactNativeWebView.postMessage(JSON.stringify(params))
    }
}

export const sendToPlaitAppSearch = (searchValue: string) => {
    if (window?.ReactNativeWebView) {
        const params = {
            type: 'moveToSearchResult',
            searchValue,
        }
        window.ReactNativeWebView.postMessage(JSON.stringify(params))
    }
}

export const sendToPlaitAppSearchTag = (tag: string) => {
    if (window?.ReactNativeWebView) {
        const params = {
            type: 'moveToSearchTag',
            tag,
        }
        window.ReactNativeWebView.postMessage(JSON.stringify(params))
    }
}

export const sendToPlaitAppExtertalLink = (uri: string) => {
    if (window?.ReactNativeWebView) {
        const params = {
            type: 'moveToExternalLink',
            uri,
        }
        window.ReactNativeWebView.postMessage(JSON.stringify(params))
    }
}

export const sendToPlaitLink = (uri: string) => {
    if (window?.ReactNativeWebView) {
        const params = {
            type: 'moveToLink',
            uri,
        }
        window.ReactNativeWebView.postMessage(JSON.stringify(params))
    }
}

export const sendToPlaitAppMoveToGoBack = () => {
    if (window?.ReactNativeWebView) {
        const params = {
            type: 'moveToGoBack',
        }
        window.ReactNativeWebView.postMessage(JSON.stringify(params))
    }
}

export const sendToAppStore = (uri: string, product: StoreProductType) => {
    if (window?.ReactNativeWebView) {
        const params = {
            type: 'moveToStore',
            uri,
            product
        }
        window.ReactNativeWebView.postMessage(JSON.stringify(params))
    }
}

export const sendToAppDeepLink = (uri: string) => {
    if (window?.ReactNativeWebView) {
        const params = {
            type: 'moveToDeepLink',
            uri,
        }
        window.ReactNativeWebView.postMessage(JSON.stringify(params))
    }
}