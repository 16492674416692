
// @types
import { respFail, respSuccess } from '../../@types/api';

// utils
import axios from '../../utils/axios';

export const getUser = (id: string) => {
    const params = {
        id
    };
    return axios.get<respSuccess | respFail>("/user/get", { params });
};

export const exitUser = () => {
    return axios.delete<respSuccess | respFail>("/user/exit");
};
