import { createSlice } from '@reduxjs/toolkit';

//? ==== types ====
import { placeStateType } from 'src/@types/models/place';
import { objectToArray } from 'src/utils/dataUtils';

const initialState: placeStateType = {
    loaded: false,
    idList: [],
    places: {},
    currentPlace: undefined,
    order: [],
};

const createIdList = (places: any, order: string[]): string[] => {
    const idList: string[] = [];
    const tempArr = objectToArray(places, order);
    for (const place of tempArr) {
        idList.push(place.id)
    }

    return idList;
}

const slice = createSlice({
    name: 'place',
    initialState,
    reducers: {
        selectPlace(state: placeStateType, action: any) {
            const newDatas: any = {};
            const placeList = action.payload;
            const idList: string[] = [];
            for (const place of placeList) {
                newDatas[place.id] = place;
                idList.push(place.id);
            }
            const currentPlace = placeList.length > 0 ? placeList[0] : undefined;

            state.loaded = true;
            state.places = newDatas;
            state.currentPlace = currentPlace;
            state.idList = idList;
        },

        selectCurrentPlace(state: placeStateType, action: any) {
            const id = action.payload;
            state.currentPlace = state.places[id];
        },

        updatePlace(state: placeStateType, action: any) {
            const place = action.payload;
            const newDatas = { ...state.places };
            newDatas[place.id] = place;

            state.places = newDatas;
            if (state.currentPlace != null && state.currentPlace.id === place.id) {
                state.currentPlace = place;
            }
            const idList = [...state.idList];
            if (!idList.includes(place.id)) {
                idList.push(place.id);
            }
            state.idList = idList;
        },

        updatePlaceOrder(state: placeStateType, action: any) {
            const order = action.payload;
            const idList = createIdList(state.places, order);

            state.order = order;
            state.idList = idList;
        },

        deletePlace(state: placeStateType, action: any) {
            const id = action.payload;
            const newDatas = { ...state.places };
            if (state.currentPlace != null && state.currentPlace.id === id) {
                var newPlace = undefined;
                for (const key in state.places) {
                    if (state.currentPlace.id !== key) {
                        newPlace = state.places[key];
                    }
                }
                state.currentPlace = newPlace;
            }
            const idList = [...state.idList];
            const newIdList = idList.filter((e) => {
                if (e === id) {
                    return false;
                } else {
                    return true;
                }
            });
            delete newDatas[id];
            state.places = newDatas;
            state.idList = newIdList;
        },

        clearPlace(state: placeStateType) {
            state = {
                loaded: false,
                places: {},
                currentPlace: undefined,
                idList: [],
                order: [],
            };
        },
    },
});

// Reducer
export default slice;

