import axios from 'axios';
// config
import { HOST_API_AUTH, AUTH_CLIENT_ID, AUTH_CLIENT_SECRET } from '../config';
import { base64EncodeString } from './dataUtils';
import { setSession, } from "./jwt";
// ----------------------------------------------------------------------

const axiosInstance = axios.create({
    baseURL: HOST_API_AUTH,
});
const pkg = require("../../package.json");
axiosInstance.defaults.headers.common.pv = pkg.buildNumber;
axiosInstance.defaults.headers.common.po = 'web';

axiosInstance.interceptors.response.use(
    (response) => {
        return response.data;
    },
    async (error) => {
        if (error.response != null && error.response.data != null) {
            console.log("axios error: ", error.response.data);
            // const { code } = error.response.data;
            // error.response.data.message = getMessge(code);
        }
        return Promise.reject(
            (error.response && error.response.data) || "Something went wrong"
        );
    }
);

export const refreshTokenData = (token: string) => {
    const payload = {
        client_id: AUTH_CLIENT_ID,
        grant_type: "refresh_token",
        refresh_token: token,
    };
    axiosInstance.defaults.headers.common.Authorization =
        "Basic " + base64EncodeString(AUTH_CLIENT_ID + ":" + AUTH_CLIENT_SECRET);
    return new Promise<{ accessToken: string, refreshToken: string }>(async (resolve, reject) => {
        try {
            const res: any = await axiosInstance.post("/oauth2/token", payload);
            const tokens = {
                accessToken: res.access_token,
                refreshToken: res.refresh_token,
            };
            setSession(tokens.accessToken, tokens.refreshToken);
            resolve(tokens)
        } catch (error) {
            console.log('error: ', error)
            reject(error);
        }
    })
}

export default axiosInstance;
