//? ==== components =====
import FillSvgIcon from 'src/components/atoms/FillSvgIcon';

//? ==== utils ====
import { translate } from 'src/utils/language';
import { PATH_DASHBOARD } from '../../../routes/paths';

const getIcon = (name: string) => (
  <FillSvgIcon src={`/icons/${name}.svg`} iconSize={1} />
);

const ICONS = {
  dashboard: getIcon('ic_dashboard'),
  place: getIcon('ic_place'),
  link: getIcon('ic_link'),
  life: getIcon('ic_life_3D'),
  scenario: getIcon('ic_scenario'),
  room: getIcon('ic_room'),
};

const navConfig = [
  {
    subheader: "ui.life_dashboard",
    items: [
      { title: "ui.device_title", path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
      { title: "ui.scenario_title", path: PATH_DASHBOARD.general.scenario, icon: ICONS.scenario },
      { title: "ui.link", path: PATH_DASHBOARD.general.link, icon: ICONS.link },
      { title: "ui.life", path: PATH_DASHBOARD.general.life, icon: ICONS.life },
      { title: "ui.room_title", path: PATH_DASHBOARD.general.room, icon: ICONS.room },
      { title: "ui.place_title", path: PATH_DASHBOARD.general.place, icon: ICONS.place },
    ],
  },
];

export default navConfig;
