import * as storage from "src/utils/storage";
//? ==== utils ====
import dispatchers from "src/redux/dispatchers";
import { queryLife } from "./life";
import { queryPlace, createPlace, getPlaceDetail } from './place'

export const bootingLogic = (userId: string) => {
    return new Promise(async (resolve, reject) => {
        try {
            storage.loadBootData();
            storage.loadOrders();
            const bootData = storage.getBootData();
            var { currentPlaceId } = bootData;
            currentPlaceId = await queryPlaceAndCreate(userId, currentPlaceId);
            getPlaceDetail(currentPlaceId);
            queryLife(currentPlaceId);
            dispatchers.system.updateLoaded(true);
            resolve('Signed');
        } catch (error: any) {
            console.log("bootingLogic error: ", error);
            reject('Error');
        }
    });
};
 
export const changeCurrentPlaceLogic = (p_id: string, withPlace?: boolean) => {
    return new Promise(async (resolve, reject) => {
        try {
            dispatchers.device.clearDevice();
            dispatchers.life.clearLife();
            dispatchers.scenario.clearScenario();
            dispatchers.room.clearRoom();
            dispatchers.link.clearLink();
            if(withPlace){
                const userId = dispatchers.user.getUserData().id;
                await queryPlaceAndCreate(userId, p_id);
            }
            dispatchers.place.selectCurrentPlace(p_id);
            storage.saveCurrentPlaceId(p_id);
            await Promise.all([
                getPlaceDetail(p_id),
                queryLife(p_id)
            ]);
   
            resolve(undefined);
        } catch (error) {
            console.log("changeCurrentPlaceLogic error: ", error);
            reject(undefined);
        }
    });
}

export const queryPlaceAndCreate = (userId: string, currentPlaceId: string | undefined) => {
    return new Promise<string>(async (resolve, reject) => {
        try {
            var placeDataList = await queryPlace(userId);
            if (placeDataList.length <= 0) {
                const newPlace = await createPlace({
                    name: "마이홈",
                });
                placeDataList = [newPlace];
            }

            if (currentPlaceId != undefined) {
                var currentPlace = undefined;
                placeDataList.map((e) => {
                    if (e.id == currentPlaceId) {
                        currentPlace = e;
                    }
                })
                if (!currentPlace) {
                    currentPlaceId = placeDataList[0].id;
                }
            } else {
                currentPlaceId = placeDataList[0].id;
            }
            dispatchers.place.selectCurrentPlace(currentPlaceId);
            resolve(currentPlaceId);
        } catch (error) {
            console.log('queryPlaceAndCreate error: ', error)
            reject(error);
        }
    })
}