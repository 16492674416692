//? ==== components ====
import { Box, BoxProps } from '@mui/material';

interface Props extends BoxProps {
    fill?: string
}
export default function MoveTopIcon({ fill, ...other }: Props) {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} {...other}>
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                viewBox="0 0 50 50" >
                <g>
                    <g>
                        <rect x="0.35" y="0.35" fill="#FFFFFF" width="49.3" height="49.3" />
                        <path fill="#F1F1F1" d="M49.3,0.7v48.6H0.7V0.7H49.3 M50,0H0v50h50V0L50,0z" />
                    </g>
                    <g>
                        <path fill="#939EAA" d="M14.81,30.43h-2.52v-2.04h7.37v2.04h-2.52v6.36h-2.33V30.43z" />
                        <path fill="#939EAA" d="M24.9,36.96c-0.65,0-1.25-0.11-1.8-0.34c-0.55-0.23-1.03-0.54-1.43-0.93c-0.4-0.39-0.71-0.85-0.94-1.38
			c-0.23-0.53-0.34-1.09-0.34-1.69v-0.02c0-0.6,0.11-1.17,0.34-1.7c0.23-0.53,0.55-1,0.95-1.39c0.41-0.4,0.89-0.71,1.44-0.94
			c0.55-0.23,1.15-0.34,1.8-0.34c0.65,0,1.25,0.11,1.8,0.34c0.55,0.23,1.03,0.54,1.43,0.93c0.4,0.39,0.71,0.85,0.94,1.38
			c0.23,0.53,0.34,1.09,0.34,1.69v0.02c0,0.6-0.11,1.17-0.34,1.7c-0.23,0.53-0.55,1-0.95,1.39c-0.41,0.4-0.89,0.71-1.44,0.94
			C26.15,36.85,25.55,36.96,24.9,36.96z M24.92,34.86c0.32,0,0.61-0.06,0.88-0.18s0.49-0.28,0.67-0.49s0.33-0.44,0.43-0.71
			c0.1-0.27,0.16-0.56,0.16-0.86v-0.02c0-0.3-0.05-0.59-0.16-0.86c-0.1-0.27-0.25-0.51-0.45-0.72s-0.42-0.37-0.69-0.5
			s-0.56-0.19-0.88-0.19s-0.61,0.06-0.87,0.18s-0.49,0.28-0.67,0.49c-0.19,0.2-0.33,0.44-0.43,0.71c-0.1,0.27-0.15,0.56-0.15,0.86
			v0.02c0,0.3,0.05,0.59,0.16,0.86c0.1,0.27,0.25,0.51,0.44,0.72s0.42,0.37,0.68,0.5S24.6,34.86,24.92,34.86z"/>
                        <path fill="#939EAA" d="M30.65,28.39h3.6c0.53,0,1.01,0.07,1.44,0.2c0.43,0.13,0.8,0.32,1.1,0.58c0.3,0.25,0.54,0.56,0.7,0.93
			c0.16,0.37,0.25,0.78,0.25,1.25v0.02c0,0.5-0.09,0.93-0.27,1.31c-0.18,0.38-0.43,0.69-0.75,0.95c-0.32,0.26-0.7,0.45-1.15,0.58
			s-0.93,0.19-1.45,0.19h-1.15v2.4h-2.33V28.39z M34.08,32.57c0.42,0,0.74-0.1,0.98-0.3c0.24-0.2,0.35-0.46,0.35-0.8v-0.02
			c0-0.36-0.12-0.64-0.35-0.83c-0.24-0.19-0.57-0.28-0.99-0.28h-1.09v2.23H34.08z"/>
                    </g>
                    <g>
                        <path fill="#939EAA" d="M32.6,20.63c-0.19,0-0.38-0.07-0.53-0.22L25,13.35l-7.07,7.07c-0.29,0.29-0.77,0.29-1.06,0s-0.29-0.77,0-1.06
			L25,11.22l8.13,8.13c0.29,0.29,0.29,0.77,0,1.06C32.98,20.56,32.79,20.63,32.6,20.63z"/>
                    </g>
                </g>
            </svg>
        </Box>
    );
}
