//? ==== hooks & utils ====
import useAuth from '../hooks/useAuth';
import { Suspense, lazy, ElementType } from 'react';

//? ==== layouts ====
import DashboardLayout from '../layouts/dashboard';
import MainLayout from '../layouts/main';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';

//? ==== guards ====
import AuthGuard from '../guards/AuthGuard';

//? ==== components ====
import LoadingScreen from 'src/components/atoms/LoadingScreen';
import RoleBasedGuard from 'src/guards/RoleBasedGuard';

import { Navigate, useRoutes, useLocation } from 'react-router-dom';
import queryString from "query-string";
import { PATH_SINGLE } from './paths';
// ----------------------------------------------------------------------

const PATH_AFTER_LOGIN = '/content';

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();

  // const isDashboard = pathname.includes('/dashboard') && isAuthenticated;
  const onlyProgress = (pathname.includes('/dashboard') && isAuthenticated) || pathname.includes('/native');

  return (
    <Suspense fallback={<LoadingScreen onlyProgress={onlyProgress} />}>
      <Component {...props} />
    </Suspense>
  );
};

// ----------------------------------------------------------------------

export default function Router() {
  const query = queryString.parse(window.location.search);
  return useRoutes([
    {
      path: 'auth',
      children: [
        { path: 'callback', element: <Callback /> },
        {
          path: 'exit', element: (
            <AuthGuard>
              <ExitPage />
            </AuthGuard>
          )
        },
      ],
    },
    //? ==== Main Routes ====
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'not-auth', element: <NotAuth /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    //? ==== Dashboard ====
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '', element: <DashBoardMainPage /> },
        { path: 'life', element: <DashBoardLifePage /> },
        { path: 'link', element: <DashBoardLinkPage /> },
        { path: 'place', element: <DashBoardPlacePage /> },
        { path: 'scenario', element: <DashBoardScenarioPage /> },
        { path: 'room', element: <DashBoardRoomPage /> },
      ],
    },
    //? ==== Contents ====
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: 'content',
          children: [
            { path: '', element: <ContentPage /> },
            {
              path: 'new', element: (
                <RoleBasedGuard>
                  <AuthGuard>
                    <ContentNewPage />
                  </AuthGuard>
                </RoleBasedGuard>
              )
            },
            {
              path: 'edit/:id', element: (
                <RoleBasedGuard>
                  <AuthGuard>
                    <ContentUpdatePage />
                  </AuthGuard>
                </RoleBasedGuard>
              )
            },
          ],
        },
      ],
    },
    {
      path: 'contents',
      children: [
        { path: 'searchResult', element: <SearchResultPage /> },
      ]
    },
    {
      path: 'content',
      children: [
        { path: 'detail/:id/*', element: <ContentDetailPage /> },
      ]
    },
    {
      path: 'be-invited',
      children: [
        {
          path: '', element: (
            <AuthGuard>
              <Navigate to={PATH_SINGLE.root("BeInvitedScreen", query)} replace />
            </AuthGuard>
          )
        },
      ]
    },
    {
      path: 'native/:screen',
      children: [
        { path: '', element: <NativePage /> },
      ]
    },
    {
      path: 'native-v2/:screen',
      children: [
        { path: '', element: <NativePage /> },
      ]
    },
    {
      path: 'single/:screen',
      children: [
        { path: '', element: <SinglePage /> },
      ]
    },
    { path: 'search/*', element: <SearchPage /> },
    { path: 'popup/:id', element: <WindowPopUp /> },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

//? ==== AUTHENTICATION ====
const Callback = Loadable(lazy(() => import('../pages/auth/Callback')));
const ExitPage = Loadable(lazy(() => import('../pages/auth/ExitPage')));

//? ==== Dashboard ====
const DashBoardMainPage = Loadable(lazy(() => import('../pages/dashboard/MainPage')));
const DashBoardLifePage = Loadable(lazy(() => import('../pages/dashboard/LifePage')));
const DashBoardLinkPage = Loadable(lazy(() => import('../pages/dashboard/LinkPage')));
const DashBoardPlacePage = Loadable(lazy(() => import('../pages/dashboard/PlacePage')));
const DashBoardScenarioPage = Loadable(lazy(() => import('../pages/dashboard/ScenarioPage')));
const DashBoardRoomPage = Loadable(lazy(() => import('../pages/dashboard/RoomPage')));

//? ==== CONTENTS ====
const ContentPage = Loadable(lazy(() => import('../pages/content/ContentPage')));
const ContentDetailPage = Loadable(lazy(() => import('../pages/content/ContentDetailPage')));
const ContentNewPage = Loadable(lazy(() => import('../pages/content/ContentNewPage')));
const ContentUpdatePage = Loadable(lazy(() => import('../pages/content/ContentUpdatePage')));

const SearchPage = Loadable(lazy(() => import('../pages/content/SearchPage')));
const SearchResultPage = Loadable(lazy(() => import('../pages/content/SearchResultPage')));

//? ==== MAIN ====
const NotAuth = Loadable(lazy(() => import('../pages/NotAuth')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const WindowPopUp = Loadable(lazy(() => import('../pages/WindowPopUp')));

//? ==== NATIVE ====
const NativePage = Loadable(lazy(() => import('../pages/NativePage')));
const SinglePage = Loadable(lazy(() => import('../pages/SinglePage')));