import { createSlice } from "@reduxjs/toolkit";

//? ==== types ====
import { lifeDataType, lifeStateType } from "src/@types/models/life";
import { objectToArray } from "src/utils/dataUtils";

const initialState: lifeStateType = {
    loaded: false,
    idList: [],
    lifes: {},
    order: [],
};

const createIdList = (data: any, order: string[]): string[] => {
    const idList: string[] = [];
    const tempArr = objectToArray(data, order);
    for (const object of tempArr) {
        idList.push(object.id);
    }
    return idList;
}

const slice = createSlice({
    name: "life",
    initialState,
    reducers: {
        selectLife(state: lifeStateType, action: any) {
            const newLifes: Record<string, lifeDataType> = {};
            const lifeList: lifeDataType[] = action.payload;

            const order = state.order;
            for (const life of lifeList) {
                newLifes[life.id] = life;
            }

            const idList = createIdList(newLifes, order);

            state.loaded = true;
            state.lifes = newLifes;
            state.idList = idList;
        },

        updateLife(state: lifeStateType, action: any) {
            const life = action.payload;
            const newLifes = { ...state.lifes };
            newLifes[life.id] = life;

            const idList = [...state.idList];
            if (!idList.includes(life.id)) {
                idList.push(life.id);
            }

            state.lifes = newLifes;
            state.idList = [...idList];
        },

        updateLifeOrder(state: lifeStateType, action: any) {
            const order = action.payload;
            const idList = createIdList(state.lifes, order);

            state.order = order;
            state.idList = idList;
        },

        deleteLife(state: lifeStateType, action: any) {
            const id = action.payload;
            const newLifes = { ...state.lifes };
            delete newLifes[id];

            const idList = [...state.idList];
            const newIdList = idList.filter((e) => {
                if (e == id) {
                    return false;
                } else {
                    return true;
                }
            });

            state.lifes = newLifes;
            state.idList = [...newIdList];
        },

        clearLife(state: lifeStateType) {
            state.loaded = false;
            state.idList = [];
            state.lifes = {};
            state.order = [];
        },
    },
});

// Reducer
export default slice;