import slice from "../slices/life";
import { dispatch, store } from "../store";
import { lifeDataType } from "src/@types/models/life";
import { objectToArray } from "../../utils/dataUtils";

export const selectLife = (lifeList: lifeDataType[]) => {
    dispatch(slice.actions.selectLife(lifeList));
};

export const updateLife = (life: lifeDataType) => {
    dispatch(slice.actions.updateLife(life));
};

export const updateLifeOrder = (order: string[]) => {
    dispatch(slice.actions.updateLifeOrder(order));
}

export const deleteLife = (id: string) => {
    dispatch(slice.actions.deleteLife(id));
};

export const clearLife = () => {
    dispatch(slice.actions.clearLife());
};

export const getLifeData = (id: string): lifeDataType => {
    return store.getState().life["lifes"][id];
};

export const getLifeListData = (): lifeDataType[] => {
    const lifes: any = store.getState().life["lifes"];
    const order: any = store.getState().life["order"];

    return objectToArray(lifes, order);
};